import React from 'react';
import { FormattedMessage } from 'react-intl';
import OAuth from '../../EmailSettings/OAuth';
import styles from './EmailConfigurationModal.module.scss';
import message from '../../../Containers/ManualSearchV2/ManualSearchMessages';

const AddNewEmailProvider = props => {
  const { emailSmtpConfiguration, setCurrentEmailConfigId, isorgDefaultEmail } = props;
  return (
    <div className={styles.addNewEmailProviderWrapper}>
      {emailSmtpConfiguration.IsThirdPartyEmailConfigurationAllowed && !isorgDefaultEmail ? (
        <div>
          <div className={styles.header}><FormattedMessage {...message.addNewEmailProviderLabel} /></div>
          <div className={styles.oAuthButtons}>
            <OAuth provider="google" isAdmin={false} setCurrentEmailConfigId={setCurrentEmailConfigId} />
            <OAuth provider="microsoft" isAdmin={false} setCurrentEmailConfigId={setCurrentEmailConfigId} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AddNewEmailProvider;
