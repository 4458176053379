import React from 'react';
import { Tag, Icon, Upload, Button, Typography } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import uuid from 'uuid';
import './JobDetail.scss';
import messages from './messages';
import HtmlViewer from '../HtmlViewer/HtmlViewer';
import JobResume from '../JobResume/JobResume';
import JobExperience from '../Common/JobExperience';
import TagsDisplayComponent from '../TagsDisplayComponent/TagsDisplayComponent';
import { getLocaleDateString } from '../../Utils/RelativeTimeCalculator';
import { generateArrayFromBooleanString } from '../../Utils/BooleanStringUtility';
import { getJobUrlsDisplayContent, getSocialMediaList } from '../../Utils/JobUtils';
import JobLocationsDisplayComponent from '../JobLocationsDisplayComponent/JobLocationsDisplayComponent';
import { listToStringCustomDefault } from '../../Utils/ListUtils';
import JobPublishDisplayCard from '../../Containers/JobPublish/JobPublishDisplayCard';
import { JobBoard, filterEnabledJobBoardPublishPortals } from '../../Utils/JobDistributionUtils/JobDistributionUtils';
import AoDAdvertisement from '../../Containers/AodAdvertisement/AoDAdvertisement';
import BooleanDisplay from '../BooleanDisplay/BooleanDisplay';
import { NewTagIcon } from '../../Icons/AryaIcons';
import jobMessage from '../JobForm/JobMessages';

class JobDetail extends React.Component {
  constructor(props) {
    super(props);
    this.getContentInTagsForm = this.getContentInTagsForm.bind(this);
  }

  getRange = (range, isRemoteJob, isPulseCustomer) => {
    const { intl } = this.props;
    if (!range || isRemoteJob) return intl.formatMessage({ ...messages.na });
    if (range === 1000) {
      return isPulseCustomer
        ? intl.formatMessage({ ...messages.autoExpandPulse })
        : intl.formatMessage({ ...messages.autoExpand });
    }
    return range;
  };

  getContentInTagsForm = (content, tagsClassName) => {
    const { intl } = this.props;
    let tags = content ? content.filter(item => item.trim() !== '') : [];
    tags = tags.length
      ? tags.map(tag => {
          return <TagsDisplayComponent className={`tag-style ${tagsClassName}`} tag={tag} />;
        })
      : intl.formatMessage({ ...messages.na });
    return tags;
  };

  getDiversityDescription = () => {
    const { job } = this.props;
    return job?.DiversityDescription?.length > 0 ? (
      job?.DiversityDescription
    ) : (
      <FormattedMessage {...jobMessage.notAvailableLabel} />
    );
  };

  getDiversityPreferences = (genders, categories) => {
    const promotedGenders = genders?.filter(val => val.IsRequired) ?? [];
    const promotedCategories = categories?.filter(val => val.IsRequired) ?? [];

    const required = [
      ...promotedGenders?.map(gender => gender.Gender),
      ...promotedCategories?.map(category => category.Category),
    ];

    return listToStringCustomDefault(required, ', ', <FormattedMessage {...jobMessage.notAvailableLabel} />);
  };

  getRemoteJobDetail(isPulseCustomer) {
    const { job } = this.props;
    return !isPulseCustomer ? (
      <>
        <div className="job-info-label">
          <FormattedMessage {...messages.remoteJob} />
        </div>
        <div className="job-detail-content">
          {job.IsRemoteJob ? (
            <FormattedMessage {...jobMessage.yesLabel} />
          ) : (
            <FormattedMessage {...jobMessage.noLabel} />
          )}
        </div>
      </>
    ) : null;
  }

  render() {
    const {
      job,
      HasResumes,
      intl,
      uploadJobResume,
      removeJobResume,
      downloadJobResume,
      usersById,
      isJobTagEnabled,
      featureToggleList,
      showSampleCandidates,
      jobDistributionPortals,
      onPublishPortal,
    } = this.props;
    let resume = intl.formatMessage({ ...messages.resumeUploaded });
    const isPaidJobService = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    const isShareJobOnSocialMediaEnabled = _.get(featureToggleList, ['ShareJobOnSocialMedia', 'IsEnabled'], false);
    const isCustomizeJobUrlEnabled = featureToggleList.CustomizeJobUrl.IsEnabled;
    const isAryaJobPostingUrlEnabled = featureToggleList.AryaJobPostingUrl.IsEnabled;
    const isShowAdditionalInfoInJobInfoEnabled = featureToggleList.ShowAdditionalInfoInJobInfo.IsEnabled;
    const jobUrlsDisplayContent = getJobUrlsDisplayContent(
      {
        aryaJobViewUrl: job.AryaJobViewUrl,
        viewUrl: job.ViewUrl,
        aryaJobApplyUrl: job.AryaJobApplyUrl,
      },
      {
        isCustomizeJobUrlEnabled,
        isAryaJobPostingUrlEnabled,
      }
    );
    const primaryLocation = job.SourcingLocations?.find(location => location.IsPrimaryLocation === true);
    const primaryLocationRange = primaryLocation ? primaryLocation.Distance : 'NA';
    const uploadButton = <Button shape="round">Upload Files</Button>;

    if (!HasResumes) {
      resume = (
        <JobResume
          Upload={Upload}
          uploadButton={uploadButton}
          jobId={job.JobId}
          resumes={(job.Resumes || []).map(resumeMetaData => ({
            uid: resumeMetaData.Id,
            name: resumeMetaData.Name,
            type: resumeMetaData.MediaType,
            status: 'done',
          }))}
          uploadJobResume={uploadJobResume}
          removeJobResume={removeJobResume}
          downloadJobResume={downloadJobResume}
        />
      );
    }

    let mustToHaveCss = 'musttohaveskill-tag-style';
    let niceToHaveCss = 'nicetohave-skill-style';
    let industryTagCss = 'industry-tag-style';
    let eduQualificationCss = 'edu-qualification-tag-style';
    if (isPaidJobService) {
      mustToHaveCss = 'musttohaveskill-tag-style-paid';
      niceToHaveCss = 'nicetohave-skill-style-paid';
      industryTagCss = 'industry-tag-style-paid';
      eduQualificationCss = 'edu-qualification-tag-style-paid';
    }

    const { JobAdvanceInfo } = job;
    const isAdvancedInfo = job?.IsAdvancedJob || isShowAdditionalInfoInJobInfoEnabled;
    const specialNotes = JobAdvanceInfo?.SpecialNotes;
    const minSalary = JobAdvanceInfo?.Salary?.MinSalary || '';
    const maxSalary = JobAdvanceInfo?.Salary?.MaxSalary || '';
    const compensationType = JobAdvanceInfo?.Salary?.CompensationType || '';
    const currency = JobAdvanceInfo?.Salary?.Currency ?? '';
    const employmentTypes = JobAdvanceInfo?.EmploymentTypes?.join(', ') || (
      <FormattedMessage {...jobMessage.notAvailableLabel} />
    );
    const workAuthorization = JobAdvanceInfo?.WorkAuthorization?.join(', ') || (
      <FormattedMessage {...jobMessage.notAvailableLabel} />
    );
    const jobDegrees = JobAdvanceInfo?.Degree?.join(', ') || <FormattedMessage {...jobMessage.notAvailableLabel} />;
    let industries = this.getContentInTagsForm(job.Industries, industryTagCss);
    const jobTags = this.getContentInTagsForm(job.Tags, 'job-tag-style');
    const jobDiversityInfo = this.getDiversityPreferences(job?.GenderPreferences, job?.CategoryPreferences);
    const isPulseCustomer =
      featureToggleList?.PaidJobService?.IsEnabled === true ||
      featureToggleList?.WhiteGloveServiceProgress?.IsEnabled === true;
    const isJobIdealCandidateResumeUploadEnabled = featureToggleList?.JobIdealCandidateResumeUpload?.IsEnabled;
    let mustSkillsList = job.MustHaves
      ? generateArrayFromBooleanString(job.MustHaves)
      : intl.formatMessage({ ...messages.na });

    let niceSkillsList = job.NiceToHaves
      ? job.NiceToHaves.split(',')
          .filter(s => s.trim() !== '')
          .map(skill => (
            <Tag className={`tag-style ${niceToHaveCss}`} key={uuid.v4()}>
              {skill}
            </Tag>
          ))
      : intl.formatMessage({ ...messages.na });

    if (isPulseCustomer) {
      mustSkillsList = generateArrayFromBooleanString(job.MustHaves)[0]?.join(', ') || (
        <FormattedMessage {...jobMessage.notAvailableLabel} />
      );
      niceSkillsList = generateArrayFromBooleanString(job.NiceToHaves)[0]?.join(', ') || (
        <FormattedMessage {...jobMessage.notAvailableLabel} />
      );
      industries = job.Industries?.join(', ') || <FormattedMessage {...jobMessage.notAvailableLabel} />;
    }

    const eduQualificationsList = job.EducationalQualifications
      ? job.EducationalQualifications.split(',')
          .filter(s => s.trim() !== '')
          .map(eduQualification => (
            <Tag className={`tag-style ${eduQualificationCss}`} key={uuid.v4()}>
              {eduQualification}
            </Tag>
          ))
      : intl.formatMessage({ ...messages.na });

    const createdDate = job.JobCode ? (
      <div className="jobdetail-created-date">
        <Icon type="clock-circle" theme="filled" style={{ color: '#faad14', marginRight: '8px' }} />
        <span>
          <FormattedMessage {...jobMessage.createdDateLabel} /> {getLocaleDateString(job.CreatedDate)}
        </span>
      </div>
    ) : (
      <div className="jobdetail-created-date">
        <Icon type="clock-circle" theme="filled" style={{ color: '#faad14', marginRight: '8px' }} />
        <span>
          <FormattedMessage {...jobMessage.createdByLabel} /> {_.get(usersById, [job.CreatedBy, 'FullName'], '')} on{' '}
          {getLocaleDateString(job.CreatedDate)}
        </span>
      </div>
    );

    const getJobSummary = job.Summary ? (
      <div className="job-summary">
        <div className="job-info-label">
          <span>
            <FormattedMessage {...messages.jobSummary} />
          </span>
        </div>
        <div className="job-summary-body">
          <span>
            <HtmlViewer forbidTags={['style']} htmlContent={job.Summary} />
          </span>
        </div>
      </div>
    ) : null;

    const enabledJobBoardPublishPortals = filterEnabledJobBoardPublishPortals(jobDistributionPortals);

    const {
      JobDistribution: { IsEnabled: isJobDistributionEnabled },
      AoDAdvertisement: { IsEnabled: isAodAdvertisementEnabled },
    } = featureToggleList;

    return (
      <div className="grid-container">
        <div className="job-detail job-info-blur">
          <div className="job-synonym">
            <div className="job-title-synonyms job-info-label">
              <span>
                <FormattedMessage {...messages.jobTitleSymonyms} />
              </span>
            </div>
            <div className="body">
              <span>{job.Synonyms ? job.Synonyms.join(', ') : null}</span>
            </div>
          </div>
          {featureToggleList?.JobSummary.IsEnabled ? getJobSummary : null}
          <div className="job-description">
            <div className="job-info-label">
              <span>
                <FormattedMessage {...messages.jobDescription} />
              </span>
            </div>
            <div className="job-description-body">
              <span>
                <HtmlViewer
                  forbidTags={['style']}
                  htmlContent={job.Description && job.Description.replace(/\n/gi, '<br />')}
                />
              </span>
            </div>
          </div>
          {specialNotes && (
            <div className="job-special-notes">
              <div className="job-info-label">
                <span>
                  <FormattedMessage {...jobMessage.jobAdditionalNotes} />
                </span>
              </div>
              <div className="job-special-notes-body">
                <span>
                  <HtmlViewer
                    forbidTags={['style']}
                    htmlContent={specialNotes && specialNotes.replace(/\n/gi, '<br />')}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="right-col">
          {isAodAdvertisementEnabled ? (
            <div className="job-info-blur highlighted-card aod-request-quote">
              <NewTagIcon style={{ position: 'absolute', top: '-25px', right: '8px', fontSize: '54px' }} />
              <AoDAdvertisement jobId={job.JobId} />
            </div>
          ) : null}
          {isJobDistributionEnabled && enabledJobBoardPublishPortals.length ? (
            <div className="job-info-blur">
              <JobPublishDisplayCard
                onPublishPortal={onPublishPortal}
                portals={enabledJobBoardPublishPortals}
                jobId={job.JobId}
                publishType={JobBoard}
                aryaJobStatus={job.AryaStatus}
              />
            </div>
          ) : null}
          {isShareJobOnSocialMediaEnabled && !showSampleCandidates && (
            <div className="job-info-blur">
              <div className="share-job-text">
                <FormattedMessage {...jobMessage.shareThisJobLabel} />
              </div>
              <div className="social-media-icons-wrapper">{getSocialMediaList(job)}</div>
            </div>
          )}
          <div className="job-given-info job-info-blur">
            {createdDate}
            <div className="openings-and-experience right-col-info-row">
              <div className="job-openings">
                <div className="job-info-label">
                  <FormattedMessage {...messages.jobOpening} />
                </div>
                <div className="job-detail-content">
                  {job.Openings ? job.Openings : intl.formatMessage({ ...messages.na })}
                </div>
              </div>
              <div className="job-experience">
                <div className="job-info-label">
                  <FormattedMessage {...messages.jobExperience} />
                </div>
                <div className="job-detail-content">
                  <JobExperience minExperience={job.MinExperience} maxExperience={job.MaxExperience} />
                </div>
              </div>
            </div>
            <div className="right-col-info-row">
              <div className="job-info-label">
                <FormattedMessage {...messages.jobLocation} />
              </div>
              <div className="job-detail-content job-location-info">
                {job.SourcingLocations?.length ? (
                  <JobLocationsDisplayComponent locations={job.SourcingLocations} visibleLocationsCount={2} />
                ) : (
                  intl.formatMessage({ ...messages.na })
                )}
              </div>
            </div>
            <div className="job-distance right-col-info-row">
              <div className="job-info-label">
                <FormattedMessage {...messages.jobMiles} />
              </div>
              <div className="job-detail-content">
                {this.getRange(primaryLocationRange, job.IsRemoteJob, isPaidJobService)}
              </div>
            </div>
            <div className="job-distance right-col-info-row">{this.getRemoteJobDetail(isPulseCustomer)}</div>
            {!isPulseCustomer && isJobIdealCandidateResumeUploadEnabled ? (
              <div className="job-resume right-col-info-row">
                <div className="job-info-label">
                  <FormattedMessage {...messages.jobResume} />
                </div>
                <div className="job-detail-content job-detail-resume-content">{resume}</div>
                <div className="resume-format">
                  <span>Supported: doc, docx, pdf, txt, html</span>
                </div>
              </div>
            ) : null}
            {jobUrlsDisplayContent ? (
              <div className="job-apply-url">
                <div className="job-info-label">{jobUrlsDisplayContent.label}</div>
                <div className="job-detail-content">
                  <Typography.Text copyable ellipsis>
                    {jobUrlsDisplayContent.url}
                  </Typography.Text>
                </div>
              </div>
            ) : null}
          </div>
          <div className="job-derived-info job-info-blur">
            <div className="job-industry right-col-info-row">
              <div className="job-info-label tag-section-label">
                <FormattedMessage {...messages.jobIndustry} />
              </div>
              {industries}
            </div>
            {isJobTagEnabled ? (
              <div className="job-tag right-col-info-row">
                <div className="job-info-label tag-section-label">
                  <FormattedMessage {...messages.jobTag} />
                </div>
                {jobTags}
              </div>
            ) : null}

            {job.MustHaves ? (
              <div className="job-must-skills right-col-info-row">
                <div className="job-info-label tag-section-label">
                  <FormattedMessage {...messages.jobMustHaveSkills} />
                </div>
                {!isPulseCustomer
                  ? mustSkillsList.map((mustHave, index) => (
                      <BooleanDisplay
                        key={index.toString()}
                        selectedTags={mustHave}
                        selectedTagClassName={mustToHaveCss}
                        readOnly
                        showAnd={index !== mustSkillsList.length - 1}
                      />
                    ))
                  : mustSkillsList}
              </div>
            ) : null}
            {job.NiceToHaves ? (
              <div className="job-nice-skills right-col-info-row">
                <div className="job-info-label tag-section-label">
                  <FormattedMessage {...messages.jobNiceToHaveSkills} />
                </div>
                {niceSkillsList}
              </div>
            ) : null}
            {job.EducationalQualifications ? (
              <div className="job-edu-qualifications right-col-info-row">
                <div className="job-info-label tag-section-label">
                  <FormattedMessage {...jobMessage.educationalQualifications} />
                </div>
                {eduQualificationsList}
              </div>
            ) : null}
            {isAdvancedInfo && JobAdvanceInfo?.EmploymentTypes ? (
              <div className="right-col-info-row">
                <div className="job-info-label tag-section-label">
                  <FormattedMessage {...jobMessage.employmentTypesLabel} />
                </div>
                {employmentTypes}
              </div>
            ) : null}
            {isAdvancedInfo && JobAdvanceInfo?.Degree ? (
              <div className="right-col-info-row">
                <div className="job-info-label tag-section-label">
                  <FormattedMessage {...jobMessage.educationLabel} />
                </div>
                {jobDegrees}
              </div>
            ) : null}
            {isAdvancedInfo && (minSalary !== '' || maxSalary !== '') ? (
              <div className="right-col-info-row-salary">
                {minSalary !== '' ? (
                  <div>
                    <div className="job-info-label tag-section-label">Minimum Salary</div>${minSalary}
                  </div>
                ) : null}
                {maxSalary !== '' ? (
                  <div>
                    <div className="job-info-label tag-section-label">Maximum Salary</div>${maxSalary}
                  </div>
                ) : null}
              </div>
            ) : null}
            {isAdvancedInfo && (compensationType !== '' || currency !== '') ? (
              <div className="right-col-info-row-salary-info">
                {compensationType !== '' ? (
                  <div>
                    <div className="job-info-label tag-section-label">Compensation Type</div>
                    {compensationType}
                  </div>
                ) : null}
                {currency !== '' ? (
                  <div>
                    <div className="job-info-label tag-section-label">Currency</div>
                    {currency}
                  </div>
                ) : null}
              </div>
            ) : null}
            {isAdvancedInfo && JobAdvanceInfo?.WorkAuthorization ? (
              <div className="right-col-info-row">
                <div className="job-info-label tag-section-label">
                  <FormattedMessage {...jobMessage.workStatusAvailable} />
                </div>
                {workAuthorization}
              </div>
            ) : null}
          </div>
          {featureToggleList?.Diversity?.IsEnabled ? (
            <div className="job-derived-info job-info-blur">
              <div className="job-industry right-col-info-row">
                <div className="job-info-label tag-section-label">
                  <FormattedMessage {...jobMessage.diversityOptionsLabel} />
                </div>
                <div className="job-diversity-info">
                  <FormattedMessage {...jobMessage.promotingDeiverseCandidatesAvailable} />
                </div>
                <div className="job-diversity-info">{jobDiversityInfo}</div>
              </div>
              <div className="job-industry right-col-info-row">
                <div className="job-info-label tag-section-label">
                  <FormattedMessage {...jobMessage.dAndIPolicyLabel} />
                </div>
                <div className="job-diversity-info">{this.getDiversityDescription()}</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default injectIntl(JobDetail);
export { JobDetail as JobDetailWithoutStore };
