import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ProfileSettings from '../../../Components/ProfileSettings/ProfileSettings';
import { getCurrentUser } from '../../../Reducers/UserSessionReducer';
import { getSignatures, getUserEmails, getUsersByGuId, getUserLocationUtilities } from '../../../Reducers/UserReducer';
import * as UserSessionActions from '../../../Actions/UserSessionActions';
import * as ConnectActions from '../../../Actions/ConnectActions';
import * as JobActions from '../../../Actions/JobActions';
import * as UserActions from '../../../Actions/UserActions';
import * as PaymentActions from '../../../Actions/AryaPayActions';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { getJobUtilities } from '../../../Reducers/JobReducer';
import {
  getAddPaymentProfileApiStatus,
  getPaymentProfile,
  getPaymentProfilesById,
} from '../../../Reducers/AryaPayReducer';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import './ProfileSettings.scss';

/* @author Prashanth Banda */

const mapStateToProps = state => ({
  CurrentUser: getCurrentUser(state),
  Signatures: getSignatures(state),
  featureToggleList: getFeatureToggleList(state),
  UserEmails: getUserEmails(state),
  jobUtilities: getJobUtilities(state),
  userUtilities: getUserLocationUtilities(state),
  usersByGuId: getUsersByGuId(state),
  paymentProfile: getPaymentProfile(state),
  paymentProfilesById: getPaymentProfilesById(state),
  savePaymentProfileApiStatus: getAddPaymentProfileApiStatus(state),
  paymnetProfileApiStatus: getApiStatus(state, 'paymnetProfileApiStatus'),
  deletePaymentCardApiStatus: getApiStatus(state, 'deletePaymentCardApiStatus'),
  locationApiStatus: getApiStatus(state, 'locationApiStatus'),
});

const mapDispatchToProps = {
  updateUserPassword: UserSessionActions.updateUserPassword,
  updateUserAttributes: UserSessionActions.updateUserAttributes,
  getEmailSignatures: ConnectActions.getEmailSignatures,
  postEmailSignature: ConnectActions.postEmailSignature,
  postEmailSignatures: ConnectActions.postEmailSignatures,
  fetchUserEmails: ConnectActions.fetchUserEmails,
  addUserEmail: ConnectActions.addUserEmail,
  deleteUserEmail: ConnectActions.deleteUserEmail,
  fetchZipCodes: JobActions.fetchZipCodes,
  resetZipcodes: JobActions.resetZipcodes,
  fetchLocations: JobActions.fetchLocations,
  fetchUserZipCodes: UserActions.fetchZipCodes,
  fetchUserLocations: UserActions.fetchLocations,
  fetchUsers: UserActions.fetchUsers,
  updateUserDetails: UserActions.updateUserDetails,
  savePaymentDetails: PaymentActions.savePaymentDetails,
  fetchPaymentProfile: PaymentActions.fetchPaymentProfile,
  deletePaymentCard: PaymentActions.deletePaymentProfile,
  resetSavePaymentDetailApiStatus: PaymentActions.resetSavePaymentDetailApiStatus,
  clearDeletePaymentCardApiStatus: PaymentActions.clearDeletePaymentCardApiStatus,
  clearZipValidationError: JobActions.clearZipValidationError,
  searchZipCode: JobActions.searchZipCode,
  clearZipCodeError: JobActions.clearZipCodeError,
  updateBillingInfo: PaymentActions.updateBillingInfo,
};

class ProfileSettingsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.updateUserProfile = this.updateUserProfile.bind(this);
    this.updateProfilePassword = this.updateProfilePassword.bind(this);
  }

  componentDidMount() {
    const { getEmailSignatures, fetchUserEmails, fetchPaymentProfile } = this.props;
    fetchUserEmails();
    getEmailSignatures();
    fetchPaymentProfile();
  }

  componentDidUpdate() {
    const { CurrentUser, fetchUsers } = this.props;
    const { isUsersFetched } = this.state;
    if (!isUsersFetched && CurrentUser.sub) {
      fetchUsers({
        userGuids: [CurrentUser.sub],
      });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isUsersFetched: true });
    }
  }

  updateUserProfile(updatedUserInfo) {
    const { updateUserAttributes } = this.props;
    updateUserAttributes(updatedUserInfo);
  }

  updateProfilePassword(oldPassword, newPassword,onSuccessfulPasswordUpdateCallback) {
    const { updateUserPassword } = this.props;
    updateUserPassword(oldPassword, newPassword, onSuccessfulPasswordUpdateCallback);
  }

  render() {
    const {
      CurrentUser,
      Signatures,
      postEmailSignature,
      UserEmails,
      postEmailSignatures,
      addUserEmail,
      deleteUserEmail,
      featureToggleList,
      fetchLocations,
      fetchZipCodes,
      resetZipcodes,
      jobUtilities,
      usersByGuId,
      updateUserDetails,
      savePaymentDetails,
      fetchPaymentProfile,
      paymentProfile,
      paymentProfilesById,
      paymnetProfileApiStatus,
      savePaymentProfileApiStatus,
      resetSavePaymentDetailApiStatus,
      deletePaymentCardApiStatus,
      deletePaymentCard,
      clearDeletePaymentCardApiStatus,
      clearZipCodeError,
      searchZipCode,
      clearZipValidationError,
      locationApiStatus,
      userUtilities,
      fetchUserLocations,
      fetchUserZipCodes,
      updateBillingInfo,
      location,
    } = this.props;
    const {
      PaidJobService: { IsEnabled: isPaidService },
      EmailWrite: { IsEnabled: isEmailWriteEnabled },
    } = featureToggleList;

    return (
      <div>
        <ProfileSettings
          onUpdateBasicInfo={this.updateUserProfile}
          user={CurrentUser}
          signatures={Signatures}
          onUpdatePassword={this.updateProfilePassword}
          postEmailSignature={postEmailSignature}
          isPaidService={isPaidService}
          postEmailSignatures={postEmailSignatures}
          userEmails={UserEmails}
          addUserEmail={addUserEmail}
          deleteUserEmail={deleteUserEmail}
          fetchZipCodes={fetchZipCodes}
          resetZipcodes={resetZipcodes}
          fetchLocations={fetchLocations}
          jobUtilities={jobUtilities}
          userDetails={_.get(usersByGuId, [CurrentUser.sub], {})}
          updateUserDetails={updateUserDetails}
          savePaymentDetails={savePaymentDetails}
          fetchPaymentProfile={fetchPaymentProfile}
          paymentProfile={paymentProfile}
          paymentProfilesById={paymentProfilesById}
          savePaymentProfileApiStatus={savePaymentProfileApiStatus}
          paymnetProfileApiStatus={paymnetProfileApiStatus}
          resetSavePaymentDetailApiStatus={resetSavePaymentDetailApiStatus}
          deletePaymentCardApiStatus={deletePaymentCardApiStatus}
          deletePaymentCard={deletePaymentCard}
          clearDeletePaymentCardApiStatus={clearDeletePaymentCardApiStatus}
          clearZipValidationError={clearZipValidationError}
          searchZipCode={searchZipCode}
          clearZipCodeError={clearZipCodeError}
          locationApiStatus={locationApiStatus}
          userUtilities={userUtilities}
          fetchUserLocations={fetchUserLocations}
          fetchUserZipCodes={fetchUserZipCodes}
          updateBillingInfo={updateBillingInfo}
          isEmailWriteEnabled={isEmailWriteEnabled}
          location={location}
        />
      </div>
    );
  }
}

export { ProfileSettingsContainer as ProfileSettingsContainerWithoutStore };

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettingsContainer);
