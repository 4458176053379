import AWS from 'aws-sdk';
import { fetchAuthSession } from 'aws-amplify/auth';
import config from '../Config/Config';

function setCredentials(credentials) {
  return {
    type: 'SET_IDENTITY_CREDENTIALS',
    payload: credentials,
  };
}

function setIdentityCredentials() {
  return async dispatch => {
    try {
      const { region, identityPoolId, roleArn, poolId } = config.auth.cognito;
      AWS.config.region = region;

      const cognitoidentity = new AWS.CognitoIdentity();
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
        RoleSessionName: 'web',
        RoleArn: roleArn,
      });

      const session = await fetchAuthSession();
      const { idToken } = session.tokens;

      const logins = { [`cognito-idp.${region}.amazonaws.com/${poolId}`]: idToken };
      const identityIdParams = {
        IdentityPoolId: identityPoolId,
        Logins: logins,
      };

      const response = await cognitoidentity.getId(identityIdParams).promise();
      const identityId = response.IdentityId;

      const credentialsForIdentityParams = {
        IdentityId: identityId,
        Logins: logins,
      };
      const credentialsResponse = await cognitoidentity
        .getCredentialsForIdentity(credentialsForIdentityParams)
        .promise();
      const credentials = credentialsResponse.Credentials;
      dispatch(setCredentials(credentials));
      return credentials;
    } catch (e) {
      return {};
    }
  };
}

export { setIdentityCredentials };
