import React from 'react';
import { Modal, Tabs, Divider } from 'antd';
import { RadioTabItem } from '../../Payment/PaymentInfo';
import { CreditCard, AdCredits } from '../../../Utils/JobDistributionUtils/JobDistributionUtils';
import styles from './AdvertisementPaymentMethod.module.scss';
import eventTypes from '../../../Analytics/EventTypes';

const { TabPane } = Tabs;

export default function AdvertisementPaymentMethod(props) {
  const { visible, onCancel, onConfirm, requiredCredits, availableCredits, validationLoading, AppName } = props;

  const [paymentMethod, setPaymentMethod] = React.useState(CreditCard);
  const availableCreditValue = availableCredits?.Arya.AvailableCredits;

  const paymentInfoRow = (leftInfoText, rightInfoText, isBold) => (
    <div className={isBold ? styles.boldPaymentInfoRow : styles.simplePaymentInfoRow}>
      <div className={styles.rowLeftInfo}>{leftInfoText}</div>
      <div className={styles.rowRightInfo}>{rightInfoText}</div>
    </div>
  );

  return (
    <div>
      <Modal
        title="Attract more candidates with Intelligent Job Advertising"
        visible={visible}
        centered
        closable={false}
        onOk={() => onConfirm(paymentMethod)}
        onCancel={onCancel}
        okText={paymentMethod === CreditCard ? 'Proceed to Payment' : 'Advertise Now'}
        cancelText="Cancel"
        okButtonProps={{
          loading: validationLoading,
          shape: 'round',
          disabled: paymentMethod === AdCredits && availableCreditValue < requiredCredits,
          'sk-event-name':
            paymentMethod === CreditCard
              ? eventTypes.job.advertiseJob.advertiseWithCard
              : eventTypes.job.advertiseJob.advertiseWithAdCredit,
        }}
        cancelButtonProps={{ shape: 'round', 'sk-event-name': eventTypes.job.advertiseJob.cancelAdvertisePayment }}
        destroyOnClose
        className={styles.paymentModalContainer}
      >
        <Tabs activeKey={paymentMethod} onChange={setPaymentMethod}>
          <TabPane
            tab={
              <RadioTabItem isActive={paymentMethod === CreditCard} value={CreditCard}>
                Pay With Credit Card
              </RadioTabItem>
            }
            key={CreditCard}
          >
            {paymentInfoRow('Total order:', `${requiredCredits} credits`, true)}
            {paymentInfoRow(
              'Note: You will be charged 1 credit for each job you Advertise. (1 credit = $200)',
              null,
              false
            )}
            <Divider />
            {paymentInfoRow('Order Details', null, true)}
            {paymentInfoRow('Price', '$200', false)}
          </TabPane>
          <TabPane
            tab={
              <RadioTabItem isActive={paymentMethod === AdCredits} value={AdCredits}>
                Pay With {AppName} Ad Credits
              </RadioTabItem>
            }
            key={AdCredits}
          >
            {paymentInfoRow('Available:', `${availableCreditValue} credits`, false)}
            {paymentInfoRow('Total order:', `${requiredCredits} credits`, true)}
            {paymentInfoRow('Note: You will be charged 1 credit for each job you Advertise. (1 credit = $200)')}
            {availableCreditValue < requiredCredits ? (
              <div className={styles.notEnoughCredits}>You don&apos;t have enough credits.</div>
            ) : null}
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
}
