import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './EmailTemplateIdentifier.module.scss';
import messages from '../../../Containers/ManualSearchV2/ManualSearchMessages';


const getEmailTemplateIdentifier = (isDrip, isSystemTemplate, isDefault, isWorkflow) => {
  let emailTemplateIdentifier;
  if (isDrip) {
    emailTemplateIdentifier = <span className={styles.templateIdentifer}><FormattedMessage {...messages.dripLabel} /></span>;
  }
  if (isWorkflow) {
    emailTemplateIdentifier = <span className={styles.templateIdentifer}><FormattedMessage {...messages.workflowLabel} /></span>;
  }
  if (isSystemTemplate) {
    emailTemplateIdentifier = <span className={styles.templateIdentifer}><FormattedMessage {...messages.recommendedLabel} /></span>;
  }
  if (isDefault) {
    emailTemplateIdentifier = <span className={styles.templateIdentifer}><FormattedMessage {...messages.defaultLabel} /></span>;
  }
  if (isSystemTemplate && isDefault) {
    emailTemplateIdentifier = (
      <>
        <span className={styles.templateIdentifer}><FormattedMessage {...messages.defaultLabel} /></span>&#160;
        <span className={styles.templateIdentifer}><FormattedMessage {...messages.recommendedLabel} /></span>
      </>
    );
  }
  return emailTemplateIdentifier;
};

export { getEmailTemplateIdentifier };
