import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Drawer, Form, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import DripsDisplayContainer from '../../../../Containers/DripsDisplayContainer/DripsDisplayContainer';
import './CreateDripTemplate.scss';
import InfoIconWithTooltip from '../../../Common/InfoIconWithTooltip/InfoIconWithTooltip';
import connectTemplateMessage from '../../../../Containers/BaseTemplates/ConnectTemplatesMessages';

function CreateDripTemplate(props) {
  const {
    visible,
    onClose,
    viewOnly,
    initialValues,
    form,
    onSubmit,
    mergeTags,
    fetchDripTemplateApiStatus,
    type,
    version,
    jobDetails,
  } = props;

  const showLoadingScreen = type === 'edit' && !['COMPLETED', 'FAILED'].includes(fetchDripTemplateApiStatus);

  const dripTemplateDrawerTitle = (
    <div className="drip-template-with-info-icon">
      <div>
        <FormattedMessage {...connectTemplateMessage.dripTemplateLabel} />
      </div>
      <InfoIconWithTooltip
        tooltipTitle={<FormattedMessage {...connectTemplateMessage.furtherMessagesInTheWorkflowWillBeStoppedWarningLabel} />}
        placement="right"
      />
    </div>
  );

  return (
    <Drawer
      className="create-template drip drawer dripemailhead"
      destroyOnClose
      title={dripTemplateDrawerTitle}
      visible={visible}
      onClose={onClose}
      width={650}
    >
      {showLoadingScreen ? (
        <Skeleton active paragraph={{ rows: 12 }} loading />
      ) : (
        <DripsDisplayContainer
          initialValues={initialValues}
          viewOnly={viewOnly}
          form={form}
          onSubmit={onSubmit}
          mergeTags={mergeTags}
          onClose={onClose}
          version={version}
          jobDetails={jobDetails}
          isCreateDripTemplate
        />
      )}
    </Drawer>
  );
}

CreateDripTemplate.propTypes = {
  initialValues: PropTypes.shape({}),
};

CreateDripTemplate.defaultProps = {
  initialValues: {},
};

export default Form.create()(CreateDripTemplate);
export { CreateDripTemplate as CreateDripTemplateWithoutForm };
