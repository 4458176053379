import React from 'react';
import { Form, Select, InputNumber, Switch, Radio, Checkbox } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as DiceUtils from '../../../../Utils/AdvancedOptionsUtils/DiceUtils';
import AdvanceSearchLocation from '../AdvanceSearchLocation/AdvanceSearchLocation';
import BoooleanSelector from '../../../Common/BooleanSelector/BooleanSelector';
import './DiceAdvancedOptions.scss';
import styles from './DiceAdvancedOptions.module.scss';
import { getDefaultSwitchValue } from '../../QuickSearchFormUtils';
import message from '../../../../Containers/ManualSearchV2/ManualSearchMessages';

const { Item } = Form;
const { Option } = Select;

function DiceAdvancedOptions(props) {
  const {
    form,
    defaultSettings,
    countryCode,
    isCountryWideSearchEnabled,
    isAlternateLocationEnabled,
    isStateWideSearchEnabled,
    intl,
  } = props;
  // const { skillsCount } = this.state;
  const salaryTypeSelect = form.getFieldDecorator('dice-salary-type', {
    initialValue: defaultSettings.SalaryType || 'Yearly',
  })(
    <Select
      key="dice-salary-type"
      placeholder={<FormattedMessage {...message.selectTypeLabel} />}
      dropdownClassName="popover-select"
      className="salary-type-select"
      getPopupContainer={trigger => trigger.parentNode}
    >
      <Option value="Yearly">
        <FormattedMessage {...message.yearlyLabel} />
      </Option>
      <Option value="Hourly">
        <FormattedMessage {...message.hourlyLabel} />
      </Option>
    </Select>
  );

  const onExcludeSocialProfiles = value => {
    if (!value) {
      form.setFieldsValue({
        'dice-last-update': undefined,
        'dice-job-types': undefined,
        'dice-salary-type': undefined,
        'dice-currency': undefined,
        'dice-min-salary': undefined,
        'dice-max-salary': undefined,
        'dice-include-candidates-without-compensation': false,
        'dice-authorized-countries': undefined,
        'dice-work-authorizations': undefined,
        'dice-security-clearance': undefined,
        'dice-relocation': null,
        'dice-include-local-candidates': false,
        'dice-travel-preference': undefined,
      });
    } else {
      form.setFieldsValue({
        'dice-last-update': undefined,
        'dice-job-types': undefined,
        'dice-salary-type': 'Yearly',
        'dice-currency': 'USD',
        'dice-min-salary': undefined,
        'dice-max-salary': undefined,
        'dice-include-candidates-without-compensation': false,
        'dice-authorized-countries': undefined,
        'dice-work-authorizations': undefined,
        'dice-security-clearance': undefined,
        'dice-relocation': null,
        'dice-include-local-candidates': false,
        'dice-travel-preference': null,
      });
    }
  };

  const minSalary = form.getFieldValue('dice-min-salary') ?? defaultSettings.MinSalary;
  const maxSalary = form.getFieldValue('dice-max-salary') ?? defaultSettings.MaxSalary;

  React.useEffect(() => {
    if ((minSalary !== undefined || maxSalary !== undefined) && !(minSalary || maxSalary)) {
      form.setFieldsValue({ 'dice-include-candidates-without-compensation': false });
    }
  }, [minSalary, maxSalary]);

  const willingnessToRelocate =
    form.getFieldValue('dice-relocation') !== undefined
      ? form.getFieldValue('dice-relocation')
      : defaultSettings.WillingToRelocate;
  const onRelocateChange = e => {
    const value = e.target.value?.toLowerCase();
    if (['anywhere', undefined].includes(value)) form.setFieldsValue({ 'dice-include-local-candidates': false });
  };

  return (
    <div className="dice">
      <div className="dice-advanced-options">
        {/* <div className="skills-options">
            {[...Array(skillsCount)].map((element, index) => (
              <div className="skills-exp-wrapper">
                <Item label={index === 0 ? 'Skills' : ''} colon={false} className="skill-form-item">
                  {form.getFieldDecorator(`dice-skills${index}`, {
                    initialValue: _.get(defaultSettings, ['Skills', index])
                      ? _.get(defaultSettings, ['Skills', index]).split('-')[0]
                      : undefined,
                  })(<Input key={`dice-skills${index.toString()}`} placeholder="Add skill" className="skills-input" />)}
                </Item>
                <Item label={index === 0 ? 'Exp Yrs' : ''} colon={false} className="exp-form-item">
                  {form.getFieldDecorator(`dice-skill-exp${index}`, {
                    initialValue: _.get(defaultSettings, ['Skills', index])
                      ? _.get(defaultSettings, ['Skills', index]).split('-')[1]
                      : undefined,
                  })(
                    <InputNumber
                      disabled={!form.getFieldValue(`dice-skills${index}`)}
                      key={`dice-skill-exp${index.toString()}`}
                      placeholder="# years"
                      className="exp-inputnumber"
                    />
                  )}
                </Item>
              </div>
            ))}
            <Button className="add-skills-button" onClick={this.addSkillOption}>
              <Icon type="plus" /> Add more skills
            </Button>
          </div> */}

        <div className={styles.formItemLabelText}>
          <FormattedMessage {...message.contactMethodLabel} />
        </div>
        <div className={styles.contactMethod}>
          <Item>
            {form.getFieldDecorator('dice-contact-method', {
              initialValue:
                typeof defaultSettings.ContactMethod === 'string'
                  ? defaultSettings.ContactMethod.split(',')
                  : ['Email'],
            })(
              <Checkbox.Group key="dice-contact-method" style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                <Checkbox value="Email">
                  <FormattedMessage {...message.emailLabel} />
                </Checkbox>
                <Checkbox value="Phone" style={{ marginLeft: '0px' }}>
                  <FormattedMessage {...message.phoneLabel} />
                </Checkbox>
              </Checkbox.Group>
            )}
          </Item>
          <Item style={{ flexGrow: '0', flexShrink: '0' }}>
            {form.getFieldDecorator('dice-contact-method-boolean', {
              initialValue: defaultSettings.ContactMethodOperator ?? 'OR',
            })(<BoooleanSelector />)}
          </Item>
        </div>

        <Item label={<FormattedMessage {...message.likelyToSwitchLabel} />} colon={false}>
          {form.getFieldDecorator('dice-likely-to-switch', {
            initialValue: defaultSettings.LikelyToMove ?? null,
          })(
            <Select
              key="dice-likely-to-switch"
              placeholder={<FormattedMessage {...message.selectOptionLabel} />}
              className="dice-advance-options-select"
              getPopupContainer={trigger => trigger.parentNode}
              dropdownClassName="popover-select"
            >
              {DiceUtils.LikelyToSwitchOptions.map((option, index) => (
                <Option value={option.value} key={index.toString()}>
                  <FormattedMessage {...message[option.displayText.replace(/[ \-\/\(\)]/g, '')]} />
                </Option>
              ))}
            </Select>
          )}
        </Item>

        <Item label={<FormattedMessage {...message.languagesLabel} />} colon={false}>
          {form.getFieldDecorator('dice-languages', {
            initialValue: defaultSettings.Language ? defaultSettings.Language.split(',') : undefined,
          })(
            <Select
              key="dice-languages"
              mode="multiple"
              placeholder={<FormattedMessage {...message.selectLanguagesLabel} />}
              className="dice-advance-options-select"
              dropdownClassName="popover-select"
              getPopupContainer={trigger => trigger.parentNode}
              showArrow
            >
              {DiceUtils.Languages.map((option, index) => (
                <Option key={index.toString()} value={option}>
                  <FormattedMessage {...message[option.replace(/[ \-\/\(\)]/g, '')]} />
                </Option>
              ))}
            </Select>
          )}
        </Item>

        <Item label={<FormattedMessage {...message.socialProfilesLabel} />} colon={false}>
          {form.getFieldDecorator('dice-social-profiles', {
            initialValue: defaultSettings.SocialProfiles ? defaultSettings.SocialProfiles.split('|') : undefined,
          })(
            <Select
              key="dice-social-profiles"
              mode="multiple"
              placeholder={<FormattedMessage {...message.enterSocialProfilesLabel} />}
              className="dice-advance-options-select"
              getPopupContainer={trigger => trigger.parentNode}
              dropdownClassName="popover-select"
            >
              {DiceUtils.SocialProfiles.map((type, index) => (
                <Option value={type} key={index.toString()}>
                  {type}
                </Option>
              ))}
            </Select>
          )}
        </Item>

        {isAlternateLocationEnabled ? (
          <AdvanceSearchLocation
            defaultAlternateLocations={defaultSettings.AlternateLocations}
            form={form}
            jobBoardFormDecorator="dice"
            maxAlternateLocationsAllowed={5}
            countryCode={countryCode}
            isCountryWideSearchEnabled={isCountryWideSearchEnabled}
            isStateWideSearchEnabled={isStateWideSearchEnabled}
            config={{ supportDistance: true }}
          />
        ) : null}

        <Item
          label={<FormattedMessage {...message.excludeSocialOnlyProfilesLabel} />}
          colon={false}
          className="switch-form-item"
        >
          {form.getFieldDecorator('dice-exclude-social-only-profiles', {
            initialValue: getDefaultSwitchValue(defaultSettings.ProfileSource, true),
            valuePropName: 'checked',
          })(<Switch key="dice-exclude-social-only-profiles" onChange={onExcludeSocialProfiles} />)}
        </Item>

        <Item label={<FormattedMessage {...message.resumeLastUpdatedInDays} />} colon={false}>
          {form.getFieldDecorator('dice-last-update', {
            initialValue: defaultSettings.LastUpdatedInDays,
          })(
            <InputNumber
              key="dice-last-update"
              placeholder={intl.formatMessage({ ...message.EnterofdaysLabel })}
              className="dice-advance-options-input"
              min={1}
            />
          )}
        </Item>

        <Item label={<FormattedMessage {...message.employmentTypeLabel} />} colon={false}>
          {form.getFieldDecorator('dice-job-types', {
            initialValue: defaultSettings.DesiredJobType ? defaultSettings.DesiredJobType.split(',') : undefined,
          })(
            <Select
              key="dice-job-types"
              mode="multiple"
              placeholder={<FormattedMessage {...message.selectTypesLabel} />}
              className="dice-advance-options-select"
              dropdownClassName="popover-select"
              getPopupContainer={trigger => trigger.parentNode}
              showArrow
            >
              {DiceUtils.JobTypes.map((type, index) => (
                <Option value={type} key={index.toString()}>
                  <FormattedMessage {...message[type.replace(/[()\-\s\\/]/g, '')]} />
                </Option>
              ))}
            </Select>
          )}
        </Item>

        <div className="salary-form-item-label">
          <FormattedMessage {...message.compensationLabel} /> {salaryTypeSelect}
        </div>
        <div className="salary-form-item" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Item>
            {form.getFieldDecorator('dice-currency', {
              initialValue: defaultSettings.SalaryCurrency || 'USD',
            })(
              <Select
                key="dice-currency"
                placeholder={<FormattedMessage {...message.selectTypeLabel} />}
                dropdownClassName="popover-select"
                className="salary-type-select"
                getPopupContainer={trigger => trigger.parentNode}
              >
                {DiceUtils.Currencies.map((option, index) => (
                  <Option value={option} key={index.toString()}>
                    {option}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
          <Item>
            {form.getFieldDecorator('dice-min-salary', { initialValue: defaultSettings.MinSalary })(
              <InputNumber
                key="dice-min-salary"
                placeholder={intl.formatMessage({ ...message.minLabel })}
                min={0}
                className="dice-minimum-salary"
              />
            )}
          </Item>
          <Item>
            {form.getFieldDecorator('dice-max-salary', { initialValue: defaultSettings.MaxSalary })(
              <InputNumber
                key="dice-max-salary"
                placeholder={intl.formatMessage({ ...message.maxLabel })}
                min={0}
                className="dice-maximum-salary"
              />
            )}
          </Item>
        </div>

        <div className={styles.formItemLabel}>
          <div className={styles.formItemLabelText}>
            <FormattedMessage {...message.includeCandidatesWithoutCompensationDisclosed} />
          </div>
          <Item colon={false}>
            {form.getFieldDecorator('dice-include-candidates-without-compensation', {
              initialValue: getDefaultSwitchValue(defaultSettings.IncludeCandidatesWithoutCompensation, false),
              valuePropName: 'checked',
            })(<Switch key="dice-include-candidates-without-compensation" disabled={!(minSalary || maxSalary)} />)}
          </Item>
        </div>

        <Item label={<FormattedMessage {...message.authorizedCountriesLabel} />} colon={false}>
          {form.getFieldDecorator('dice-authorized-countries', {
            initialValue: defaultSettings.AuthorizedCountries
              ? defaultSettings.AuthorizedCountries.split(',')
              : undefined,
          })(
            <Select
              key="dice-authorized-countries"
              mode="multiple"
              placeholder={<FormattedMessage {...message.selectAuthorizedCountriesLabel} />}
              className="dice-advance-options-select"
              dropdownClassName="popover-select"
              getPopupContainer={trigger => trigger.parentNode}
              showArrow
            >
              {DiceUtils.AuthorizedCountries.map(country => (
                <Option value={country.value} key={country.value}>
                  <FormattedMessage {...message[country.displayText.replace(/[()\-\s\\/]/g, '')]} />
                </Option>
              ))}
            </Select>
          )}
        </Item>

        <Item label={<FormattedMessage {...message.workPermitsLabel} />} colon={false}>
          {form.getFieldDecorator('dice-work-authorizations', {
            initialValue: defaultSettings.WorkStatus ? defaultSettings.WorkStatus.split(',') : undefined,
          })(
            <Select
              key="dice-work-authorizations"
              mode="multiple"
              placeholder={<FormattedMessage {...message.selectWorkPermitsLabel} />}
              className="dice-advance-options-select"
              dropdownClassName="popover-select"
              getPopupContainer={trigger => trigger.parentNode}
              showArrow
            >
              {DiceUtils.WorkAuthorizations.map(authorization => (
                <Option key={authorization.value} value={authorization.value}>
                  <FormattedMessage {...message[authorization.displayText.replace(/[ \-\/\(\)]/g, '')]} />
                </Option>
              ))}
            </Select>
          )}
        </Item>

        <Item label={<FormattedMessage {...message.hasSecurityClearance} />} colon={false}>
          {form.getFieldDecorator('dice-security-clearance', {
            initialValue: defaultSettings.SecurityClearance,
          })(
            <Radio.Group key="dice-security-clearance">
              <Radio value="true">
                <FormattedMessage {...message.yesLabel} />
              </Radio>
              <Radio value="false">
                <FormattedMessage {...message.noLabel} />
              </Radio>
              <Radio value={undefined}>
                <FormattedMessage {...message.doesNotMatterLabel} />
              </Radio>
            </Radio.Group>
          )}
        </Item>

        <Item label={<FormattedMessage {...message.willingToRelocateLabel} />} colon={false}>
          {form.getFieldDecorator('dice-relocation', {
            initialValue: defaultSettings.WillingToRelocate ?? null,
          })(
            <Radio.Group key="dice-relocation" className="relocation-radio-options" onChange={onRelocateChange}>
              <Radio className="relocation-option" value="AnyWhere">
                <FormattedMessage {...message.willingToRelocateAnywhereLabel} />
              </Radio>
              <Radio className="relocation-option" value="PreferredLocationsOnly">
                <FormattedMessage {...message.willingToRelocateToSpecifiedLocationLabel} />
              </Radio>
              <Radio className="relocation-option" value={null}>
                <FormattedMessage {...message.doesNotMatterLabel} />
              </Radio>
            </Radio.Group>
          )}
        </Item>

        <div className={styles.formItemLabel}>
          <div className={styles.formItemLabelText}>
            <FormattedMessage {...message.includeCandidatesLivingInLocationSearched} />
          </div>
          <Item colon={false}>
            {form.getFieldDecorator('dice-include-local-candidates', {
              initialValue: getDefaultSwitchValue(defaultSettings.WillingToRelocateIncludeLocals, false),
              valuePropName: 'checked',
            })(<Switch key="dice-include-local-candidates" disabled={!willingnessToRelocate} />)}
          </Item>
        </div>

        <Item
          label={<FormattedMessage {...message.excludeJobLocationLabel} />}
          colon={false}
          className="switch-form-item"
        >
          {form.getFieldDecorator('dice-include-country-search', {
            initialValue: getDefaultSwitchValue(defaultSettings.ExcludeLocationInSearch, false),
            valuePropName: 'checked',
          })(<Switch key="dice-include-country-search" />)}
        </Item>

        <Item label={<FormattedMessage {...message.travelPreferenceLabel} />} colon={false}>
          {form.getFieldDecorator('dice-travel-preference', {
            initialValue: defaultSettings.TravelPreference ?? null,
          })(
            <Select
              key="dice-travel-preference"
              placeholder={<FormattedMessage {...message.selectTravelPreferenceLabel} />}
              className="dice-advance-options-select"
              dropdownClassName="popover-select"
              getPopupContainer={trigger => trigger.parentNode}
              showArrow
            >
              {DiceUtils.TravelPreferenceOptions.map(preference => (
                <Option value={preference.value} key={preference.value}>
                  <FormattedMessage {...message[preference.displayText.replace(/[ \-\/\(\)]/g, '')]} />
                </Option>
              ))}
            </Select>
          )}
        </Item>

        <Item label={<FormattedMessage {...message.exclude3rdPartyLabel} />} colon={false} className="switch-form-item">
          {form.getFieldDecorator('dice-exclude-3rd-party', {
            initialValue: getDefaultSwitchValue(defaultSettings.ShouldExcludeThirdParty, true),
            valuePropName: 'checked',
          })(<Switch key="dice-exclude-3rd-party" />)}
        </Item>

        <Item label={<FormattedMessage {...message.hasPatentLabel} />} colon={false} className="switch-form-item">
          {form.getFieldDecorator('dice-has-patent', {
            initialValue: getDefaultSwitchValue(defaultSettings.HasPatent, false),
            valuePropName: 'checked',
          })(<Switch key="dice-has-patent" />)}
        </Item>

        <Item label={<FormattedMessage {...message.excludeFounderLabel} />} colon={false} className="switch-form-item">
          {form.getFieldDecorator('dice-exclude-founder', {
            initialValue: getDefaultSwitchValue(defaultSettings.ShouldExcludeFounders, false),
            valuePropName: 'checked',
          })(<Switch key="dice-exclude-founder" />)}
        </Item>
      </div>
    </div>
  );
}

export default injectIntl(Form.create()(DiceAdvancedOptions));
export { DiceAdvancedOptions as DiceAdvancedOptionsWithoutInjectIntl };
