import React from 'react';
import { Select, Tooltip, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './SubSegmentStyles.module.scss';
import { EllipsedText } from '../../Utils/TextUtils';
import messages from '../ManualSearchV2/ManualSearchMessages';

const { Option } = Select;
const SubSegmentSelectBox = props => {
  const {
    selectStyle,
    handleSubSegmentOption,
    onPopupScroll,
    dropDownRender,
    subSegmentList,
    subsegmentCandidateCount,
    extraWidth,
    selectedSubsegmentId,
    isCandidateAssociationVisible = true,
    mode,
  } = props;

  return (
    <Select
      style={selectStyle}
      dropdownStyle={{ zIndex: 3000 }}
      placeholder={<FormattedMessage {...messages.selectSubSegment} />}
      dropdownMenuStyle={{ padding: '4px 10px' }}
      onChange={handleSubSegmentOption}
      onPopupScroll={onPopupScroll}
      dropdownRender={dropDownRender}
      value={selectedSubsegmentId != null ? selectedSubsegmentId : undefined}
      disabled={mode === 'update'}
      getPopupContainer={subSegmentSelectBoxTrigger => subSegmentSelectBoxTrigger.parentElement}
    >
      {subSegmentList.map(item => (
        <Option className={styles.subsegmentOption} value={item.Id} key={item.Id}>
          <div><EllipsedText text={item.Name} maxTextLength={20} zIndex={3100} tooltipPlacement="right" /></div>

          {isCandidateAssociationVisible ? (
            <div
              className={styles.subsegmentCandidateCount}
              style={{
                width: `${subsegmentCandidateCount}`.length * 10 + extraWidth,
              }}
            >
              <Icon type="usergroup-add" /> {item.AssociatedCandidateCount}
            </div>
          ) : null}
        </Option>
      ))}
    </Select>
  );
};

export default SubSegmentSelectBox;
