import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import handleLogout from '../../Hooks/handleLogout';

export default function UpdatePasswordButton(props) {
  const { signOut } = useAuthenticator(context => [context.user]);
  const { renderProp } = props;
  const onSuccessfulPasswordUpdateCallback = async () => {
    await handleLogout();
    await signOut({ global: true });
  };
  return (
    renderProp(onSuccessfulPasswordUpdateCallback)
  )
}
