import { closeBroadcastChannel } from '../Events/BroadcastNotificationHandler';
import { unloadSubscribers } from '../PubSub/Subscribers';
import { revokeCognitoRefreshToken } from '../Repository/CognitoRepository';

async function handleLogout() {
    let linkUrl = '/';
    const client = localStorage.getItem('Client');
    const { hostname } = window.location;
    if (client && hostname.includes('goarya.com')) {
      linkUrl = `/${client}`;
    }
    closeBroadcastChannel();
    sessionStorage.removeItem('aryaidp');
    unloadSubscribers();
    await revokeCognitoRefreshToken();
    window.location.href = linkUrl;
}

export default handleLogout;
