import { CookieStorage, defaultStorage } from 'aws-amplify/utils';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import langConfig from '../langConfig';
import config from '../Config/Config';

function getDomain() {
  const hostName = window.location.hostname;
  const parts = hostName.split('.');
  if (parts.length >= 2) {
    return parts.slice(-2).join('.');
  }
  return hostName;
}

export function configureCognito() {
  const awsConfig = {
    Auth: {
      Cognito: {
        userPoolClientId: config.auth.cognito.clientId,
        userPoolId: config.auth.cognito.poolId,
        loginWith: {
          username: false,
          email: true,
          phone: false,
        },
      },
    },
  };

  class CookieStorageWithEnvContext extends CookieStorage {
    constructor(data) {
      super(data);
      this.prefix = `${data.prefix}_`;
    }

    async setItem(key, value) {
      await super.setItem(this.prefix + key, value);
    }

    async getItem(key) {
      return super.getItem(this.prefix + key);
    }

    async removeItem(key) {
      await super.removeItem(this.prefix + key);
    }
  }

  Amplify.configure(awsConfig);
  const domainName = getDomain();
  if (domainName === 'goarya.com') {
    cognitoUserPoolsTokenProvider.setKeyValueStorage(
      new CookieStorageWithEnvContext({
        domain: 'goarya.com',
        path: '/',
        secure: true,
        prefix: config.auth.arya.cookieKey,
        httpOnly: true,
      })
    );
  } else {
    // domain other than goarya should use local storage
    cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);
  }

  window.appLocale = langConfig.en;
}
