import React from 'react';
import { Tag, Icon, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import { EllipsedText } from '../../Utils/TextUtils';
import { changeCursorPosition } from '../../Utils/ManualSearchUtils';
import { getFormattedMissingSkills } from '../../Utils/MissingSkillsUtils';
import message from '../Placeholders/PlaceholdersMessages';

export const CurrentTags = ({
  tags,
  deSelectTag,
  selectTag,
  currentIndex,
  closable,
  className,
  readOnly,
  shouldHighlight,
}) => {
  if (!tags?.length) return null;
  const style = shouldHighlight ? { backgroundColor: '#ADD8E6' } : {};
  return tags?.map((tag, index) => (
    <React.Fragment key={`${tag} - ${index}`}>
      <Tag
        style={style}
        className={className}
        closable={!readOnly && closable}
        onClose={e => {
          if (closable) {
            e.preventDefault();
            deSelectTag(tag, currentIndex, shouldHighlight);
          }
        }}
        onClick={() => {
          if (!closable) selectTag(tag, currentIndex);
        }}
      >
        {!(readOnly || closable) ? <Icon type="plus" /> : null}
        <EllipsedText text={tag} maxTextLength={20} />
      </Tag>
      {!(index === tags.length - 1 && readOnly) ? <span className="boolean-string-or-text"> <FormattedMessage {...message.oRLabel} /> </span> : null}
    </React.Fragment>
  ));
};

export default function EditableTagGroup(props) {
  const {
    selectedTags,
    suggestedTags,
    currentIndex,
    deSelectTag,
    selectTag,
    selectedTagClassName,
    suggestedTagClassName,
    inputVisibility,
    setInputVisibilityKey,
    inputValue,
    setInputValue,
    readOnly,
    isMustHaveSection,
    shouldHighlight,
  } = props;

  const [isDoubleQuotesAllowed, setIsDoubleQuotesAllowed] = React.useState(true);

  const onMustHavesChange = value => {
    const formattedMustHave = getFormattedMissingSkills(
      value,
      inputValue,
      isDoubleQuotesAllowed,
      setIsDoubleQuotesAllowed
    );
    setInputValue(formattedMustHave);
  };

  const inputRef = React.useRef();

  React.useEffect(() => {
    if (inputVisibility) {
      inputRef.current.focus();
    }
  }, [inputVisibility]);

  React.useEffect(() => {
    if (inputVisibility) {
      const inputField = inputRef.current?.input;
      changeCursorPosition(inputField, inputValue, isDoubleQuotesAllowed);
    }
  }, [inputValue, isDoubleQuotesAllowed, inputVisibility]);

  const hideInput = () => {
    setInputVisibilityKey('');
    setInputValue('');
  };
  const handleInputConfirm = () => {
    if (inputValue.trim()) {
      selectTag(inputValue, currentIndex, shouldHighlight);
    }
    hideInput();
  };

  const onInputBlur = debounce(hideInput, 500);

  const addMoreInputTag = () =>
    !inputVisibility ? (
      <Tag
        className="add-more-tag"
        onClick={() => {
          setInputVisibilityKey(currentIndex);
        }}
      >
        <Icon type="plus" /> <FormattedMessage {...message.addMoreLabel} />
      </Tag>
    ) : (
      <Input
        ref={inputRef}
        size="small"
        className="add-more-input"
        value={inputValue}
        onChange={e => onMustHavesChange(e.target.value)}
        onPressEnter={handleInputConfirm}
        onBlur={onInputBlur}
      />
    );

  return (
    <div>
      <CurrentTags
        deSelectTag={deSelectTag}
        tags={selectedTags}
        currentIndex={currentIndex}
        closable
        className={selectedTagClassName}
        readOnly={readOnly}
        shouldHighlight={shouldHighlight}
      />
      <CurrentTags
        selectTag={selectTag}
        tags={suggestedTags}
        currentIndex={currentIndex}
        className={suggestedTagClassName}
      />
      {!readOnly ? addMoreInputTag() : null}
    </div>
  );
}
