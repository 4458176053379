import config from '../Config/Config';
import { cognitoTokenRevokeClient } from './BaseRepository';

export async function revokeCognitoRefreshToken() {
  const refreshToken = getRefreshToken();
  const body = new URLSearchParams();
  body.append('token', refreshToken);
  body.append('client_id', config.auth.cognito.clientId);
  await cognitoTokenRevokeClient.post("revoke", body);
}

function getRefreshToken() {
  const refreshTokenKey = Object.keys(localStorage)
    .find(key => key.match(/CognitoIdentityServiceProvider\.[^.]+\.[^.]+\.refreshToken$/));
  if (refreshTokenKey) {
    return localStorage.getItem(refreshTokenKey);
  }
  console.log("No refresh token found in localStorage");
  return null;
}
