import React, { useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import RoutesContainer from './routes';
import config from './Config/Config';

const isDev = process.env.NODE_ENV === 'development';

export default function App() {
  const [isAuthenticated, setAuthenicated] = React.useState(false);
  useEffect(() => {
    if (!isDev) {
      (async function checkSession() {
        try {
          const authenticatedUser = await getCurrentUser();
          if (!authenticatedUser) {
            window.location.href = `${config.auth.arya.loginUrl}/servicelogin?cs=true&service=arya&redirect=${window.location.href}`;
          } else {
            setAuthenicated(true);
          }
        } catch (error) {
          window.location.href = `${config.auth.arya.loginUrl}/servicelogin?cs=true&service=arya&redirect=${window.location.href}`;
        }
      })();
    }
  }, []);

  if (isDev) {
    return (
      <Authenticator hideSignUp>
        <RoutesContainer />
      </Authenticator>
    );
  }
  if (!isAuthenticated) return null;

  return <RoutesContainer />;
}
