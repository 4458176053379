import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuthenticator } from '@aws-amplify/ui-react';
import messages from './messages';
import handleLogout from '../../Hooks/handleLogout';

function LogOutButton(props) {
  const { renderProp } = props;
  const { signOut } = useAuthenticator(context => [context.user]);
  

  const handleClick = async () => {
    await handleLogout();
    await signOut();
  };

  return <>{renderProp(handleClick, <FormattedMessage {...messages.logout} />)}</>;
}

export default LogOutButton;
