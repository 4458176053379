import React from 'react';
import _ from 'lodash';
import uuid from 'uuid';
import {
  generateArrayFromBooleanString,
  generateMustsBooleanString,
  removeWhiteSpaceFromDoubeQuotedStrings,
  sanitizeKeyword,
} from './BooleanStringUtility';
import { generateMandatorySkillsArray, generateSkillsObject } from './CandidateRejectReasonsUtils';
import { convertFromDays, convertToDays, getTimePeriodWithUnit } from './DaysConversion';
import { getSourcingLocations, getSourcingLocationsOnFeatureToggle } from './LocationUtils';
import { getIsSourceAuthorized, getSourceName } from './SourceUtils';
import { getUserSkillSearchPreference } from './LocalStorageUtils';
import { getStateForPayload, getCityStateForPayload } from '../Components/QuickSearchForm/QuickSearchFormUtils';
import { fetchSkillIntel } from '../Repository/ManualSearchRepository';

const locationTypes = {
  Location: 'Location',
  MultiLocation: 'MultiLocation',
};

export const getManualSearchCandidatesCountPerBatch = (isAdvancedSearchAggregationFiltersEnabled, candidateContext) => {
  return isAdvancedSearchAggregationFiltersEnabled && candidateContext === 'job' ? 50 : 25;
};
const getValidMobileNumberWithoutCharacters = ({ mobile }) => {
  return mobile?.replace(/[-\s()[\]]/g, '');
};

const generateManualSearchPayload = ({
  formValues,
  locationType,
  activeSearchString,
  sources,
  advancedSettings,
  id,
  isAdvancedSearchV2Enabled = false,
}) => {
  const {
    Titles = [],
    VisaStatuses,
    PreferredSkills,
    ExcludedSkills,
    MustHaves,
    QueryString = '',
    CountryCode,
    IsStateWideSearch,
    Location,
    Zipcode,
    AutoExpand,
    Range,
    IsRemoteJob: IsRemoteLocation,
    SourcingLocations,
    MinExperience,
    MaxExperience,
    ResumeUpdateDurationQuantity,
    ResumeUpdateDurationType,
    Degree,
    School,
    Specialization,
    Certification,
    PreferredCompany,
    IsCurrentCompany,
    IsPastCompany,
    ExcludedCompanies,
    PreferredIndustry,
    ExcludedIndustries,
    Name,
    Email,
    Mobile,
    IsExactTitleMatchRequired,
    ExcludeSegments,
  } = formValues;
  let clonedAdvancedSettings = _.cloneDeep(advancedSettings) || {};
  const resumeUpdateDuration = ResumeUpdateDurationQuantity
    ? convertToDays(ResumeUpdateDurationQuantity, ResumeUpdateDurationType)
    : null;
  const payload = {
    Titles: Titles?.map(x => ({ Title: x.name, IsPreferred: x.isSelected })),
    ActiveSearchString: activeSearchString,
    Skills: {
      Prefers: PreferredSkills?.map(x => ({
        SkillName: x.name,
        IsProminent: x.isProminent,
        IsPreferred: x.isPreferred,
        AliasSkills: x.aliasSkills?.filter(y => y.isSelected).map(y => y.name),
        AlternateSkills: x.alternateSkills?.map(y => y.name),
      })),
      Musts: generateMustsBooleanString(generateMandatorySkillsArray(MustHaves)),
      Excludes: ExcludedSkills?.filter(x => x.isSelected).map(x => x.name),
      RawMusts: generateMandatorySkillsArray(MustHaves)?.map(x => x?.[0]),
    },
    QueryString: removeWhiteSpaceFromDoubeQuotedStrings(QueryString),
    VisaStatuses,
    Experience: {
      MinYears: MinExperience || undefined,
      MaxYears: MaxExperience || undefined,
    },
    LastUpdatedFrom: resumeUpdateDuration,
    LastUpdatedFromRaw: getTimePeriodWithUnit(ResumeUpdateDurationQuantity, ResumeUpdateDurationType),
    Education: {
      Degree,
      School,
      Specialization,
      Certification,
    },
    Companies: {
      Musts: PreferredCompany?.map(x => ({ Name: x, IsPreferred: true })),
      Excludes: ExcludedCompanies,
    },
    Industries: {
      Musts: PreferredIndustry?.map(x => ({ Name: x, IsPreferred: true })),
      Excludes: ExcludedIndustries,
    },
    Name: Name?.trim() === '' ? null : Name,
    Email: Email?.trim() === '' ? null : Email,
    Mobile: Mobile?.trim() === '' ? null : getValidMobileNumberWithoutCharacters({ mobile: Mobile }),
  };

  if (IsRemoteLocation && locationType === locationTypes.Location) {
    payload.Locations = [{ CountryCode, IsPrimaryLocation: true }];
    payload.IsRemoteLocation = IsRemoteLocation;
  } else if (locationType === locationTypes.MultiLocation) {
    const updatedSourcingLocations = getSourcingLocationsOnFeatureToggle(
      SourcingLocations,
      IsRemoteLocation,
      isAdvancedSearchV2Enabled
    );
    const sourcingLocations = getSourcingLocations(updatedSourcingLocations, Range);
    sourcingLocations.forEach(sourcingLocation => {
      const refinedSourcingLocation = sourcingLocation;
      delete refinedSourcingLocation.PostalCode;
      return refinedSourcingLocation;
    });
    payload.Locations = sourcingLocations;
    payload.IsMultiLocation = true;
    if (IsRemoteLocation) {
      payload.IsRemoteLocation = IsRemoteLocation;
    }
  } else {
    const locationPayload = {
      CountryCode,
      Postalcode: Zipcode,
      IsPrimaryLocation: true,
    };
    if (IsStateWideSearch) {
      locationPayload.State = getStateForPayload(Location);
    } else {
      locationPayload.Location = getCityStateForPayload(Location);
    }
    payload.Locations = [locationPayload];
    payload.IsMultiLocation = false;
  }

  if (IsRemoteLocation || IsStateWideSearch) {
    payload.AutoExpand = false;
  } else {
    payload.AutoExpand = AutoExpand;
  }
  payload.Distance = Range;
  payload.Sources = sources;
  if (ExcludeSegments) {
    clonedAdvancedSettings = {
      ...clonedAdvancedSettings,
      Internal: { ExcludeSegments },
    };
  } else {
    clonedAdvancedSettings = _.omit(advancedSettings, ['Internal']);
  }
  payload.PortalQueries = clonedAdvancedSettings;
  if (id) payload.Id = id;
  if (IsExactTitleMatchRequired) payload.IsExactTitleMatchRequired = IsExactTitleMatchRequired;
  if (PreferredCompany?.length) {
    payload.Companies.IsCurrent = IsCurrentCompany;
    payload.Companies.IsPast = IsPastCompany;
  }

  return payload;
};

const mapSkillSuggestions = (skills, isSelected) => skills?.map(x => ({ isSelected, name: x.name ?? x })) ?? [];

const mapSkillSuggestionsWithMaxSelectionLimit = (
  skills,
  allowedSelectionValue,
  fallbackSelectionValue,
  maxAllowedSelectionValueCount = 10
) =>
  skills?.map((skill, index) => ({
    isSelected: index < maxAllowedSelectionValueCount ? allowedSelectionValue : fallbackSelectionValue,
    name: skill.name ?? skill,
  })) ?? [];

const getSkillsIntel = async (titles, skills) => {
  try {
    const skillsIntel = await fetchSkillIntel({ titles, skills });
    const _skillsIntel = {};
    Object.entries(skillsIntel.data).forEach(x => {
      const [key, value] = x;
      _skillsIntel[key.toLowerCase()] = value;
    });
    return _skillsIntel;
  } catch {
    return {};
  }
};

const getSkillsWithIntel = async (titles, skills) => {
  const skillNames = skills.map(x => x.SkillName);
  let skillsIntel = {};
  if (titles.length) {
    skillsIntel = await getSkillsIntel(titles, skillNames);
  }
  return skills?.map(skill => {
    const currentSkillIntelValue = skillsIntel[skill.SkillName?.toLowerCase()];
    if (_.isEmpty(currentSkillIntelValue)) {
      return {
        ...skill,
        AliasSkills: mapSkillSuggestions(skill.AliasSkills, true),
        AlternateSkills: mapSkillSuggestions(skill.AlternateSkills, false),
      };
    }
    const { AliasSkills, AlternateSkills, MaxRecommendedAliasSkillCount } = currentSkillIntelValue;
    const aliasSkillsIntel = mapSkillSuggestions(AliasSkills, false);
    const alternateSkillsIntel = mapSkillSuggestions(AlternateSkills, false);
    return {
      ...skill,
      AliasSkills: skill.IsPreferred
        ? _.uniqBy(mapSkillSuggestions(skill.AliasSkills, true).concat(aliasSkillsIntel), 'name')
        : _.uniqBy(mapSkillSuggestions(skill.AliasSkills, false).concat(aliasSkillsIntel), 'name'),
      AlternateSkills: _.uniqBy(alternateSkillsIntel, 'name'),
      MaxRecommendedAliasSkillCount,
    };
  });
};

const getPreferredSkills = async ({ titles, prefers = [], isAutoSelectionAllowed }) => {
  if (!prefers.length) return [];
  const titleNames = titles.filter(x => x.IsPreferred).map(x => x.Title);
  const sortedSkills = _.sortBy(prefers, [x => (x.IsProminent ? -1 : 1), x => (x.IsPreferred ? -1 : 1)]);
  const skillsWithIntel = await getSkillsWithIntel(titleNames, sortedSkills);
  return skillsWithIntel.map(x => ({
    id: uuid.v4(),
    name: x.SkillName,
    isProminent: isAutoSelectionAllowed ? x.IsProminent : false,
    isPreferred: isAutoSelectionAllowed ? x.IsPreferred : false,
    aliasSkills: x.AliasSkills,
    alternateSkills: x.AlternateSkills,
    MaxRecommendedAliasSkillCount: x.MaxRecommendedAliasSkillCount,
  }));
};

const getDefaultFormValuesFromAryaGeneratedCriteria = async ({
  aryaGeneratedCriteria = {},
  currentJobDetails,
  currentUserDetails,
  candidateContext = 'job',
  isPreferredKeywordAutoSelectEnabled,
}) => {
  const { Titles = [], Skills = {}, QueryString, ActiveSearchString } = aryaGeneratedCriteria;
  const savedActiveSearchString = getUserSkillSearchPreference(currentUserDetails?.UserGuid);
  const {
    MinExperience,
    MaxExperience,
    LastUpdatedFrom = 365,
    IsMultiLocationJob,
    CountryCode,
    Location,
    Zipcode,
    AutoExpand,
    Range,
    IsRemoteJob: IsRemoteLocation,
    SourcingLocations,
    Industries,
    ExcludeCompanies,
    MustHaves,
  } = currentJobDetails;
  const resumeLastUpdated = convertFromDays(LastUpdatedFrom);
  const defaultFormValues = {
    Titles:
      candidateContext === 'job'
        ? Titles.map(x => ({
            id: uuid.v4(),
            isSelected: isPreferredKeywordAutoSelectEnabled ? x.IsPreferred : false,
            name: x.Title,
          }))
        : [],
    Skills:
      candidateContext === 'job'
        ? {
            Prefers: await getPreferredSkills({
              titles: Titles,
              prefers: Skills.Prefers,
              isAutoSelectionAllowed: isPreferredKeywordAutoSelectEnabled,
            }),
            Keywords: Skills.Prefers?.map(x => x.SkillName),
            Musts: generateSkillsObject(getMustHaves(MustHaves)),
          }
        : {
            Prefers: [],
            Keywords: [],
          },
    QueryString,
    ActiveSearchString: candidateContext === 'job' ? savedActiveSearchString || ActiveSearchString : ActiveSearchString,
    Experience: { MinExperience, MaxExperience },
    ResumeUpdateDurationType: resumeLastUpdated.resumeUpdateDurationType,
    ResumeUpdateDurationQuantity: resumeLastUpdated.resumeUpdateDurationQuantity,
    LocationType: IsMultiLocationJob ? 'MultiLocation' : 'Location',
    Location: {
      CountryCode,
      Location,
      Zipcode,
    },
    SourcingLocations,
    AutoExpand,
    Range,
    IsRemoteLocation,
    Industries: { Musts: Industries?.filter(x => x) },
    Companies: { Excludes: ExcludeCompanies },
  };
  if (_.isEmpty(aryaGeneratedCriteria)) {
    const { JobTitle: jobTitle, Synonyms: jobSynonyms = [] } = currentJobDetails;
    if (candidateContext === 'job') {
      defaultFormValues.Titles = [jobTitle, ...jobSynonyms].map(x => ({
        id: uuid.v4(),
        isSelected: true,
        name: x,
      }));
    }
    if (candidateContext === 'job') defaultFormValues.ActiveSearchString = savedActiveSearchString || 'AI';
    if (candidateContext === 'segment') defaultFormValues.ActiveSearchString = 'Manual';
  }
  return defaultFormValues;
};

const getJobLevelOrCriteriaValues = ({ currentJobDetails, criteria }) => {
  let locations;
  let range;
  let isMultiLocation;
  let experience;
  let autoExpand;
  let isRemoteLocation;
  const isJobDetailsExists = !_.isEmpty(currentJobDetails);
  if (isJobDetailsExists) {
    const {
      SourcingLocations,
      IsMultiLocationJob,
      Range,
      AutoExpand,
      IsRemoteJob: IsRemoteLocation,
      MinExperience,
      MaxExperience,
    } = currentJobDetails;
    locations = SourcingLocations;
    isMultiLocation = IsMultiLocationJob;
    experience = { MinExperience, MaxExperience };
    range = Range;
    autoExpand = AutoExpand;
    isRemoteLocation = IsRemoteLocation;
  } else {
    const { IsMultiLocation, AutoExpand, IsRemoteLocation, Distance, Locations = [], Experience = {} } = criteria;
    const { MinYears, MaxYears } = Experience;
    locations = Locations;
    isMultiLocation = IsMultiLocation;
    experience = { MinExperience: MinYears, MaxExperience: MaxYears };
    range = Distance;
    autoExpand = AutoExpand;
    isRemoteLocation = IsRemoteLocation;
  }
  return { locations, range, isMultiLocation, experience, autoExpand, isRemoteLocation };
};

function isNumericString(str) {
  return /^\d+$/.test(str);
}

function getMustHaves(mustHaves) {
  const mustHavesArray = generateArrayFromBooleanString(mustHaves);
  const formattedSkillsArray = [];
  mustHavesArray?.forEach(skillArray => {
    const formattedSkillArray = skillArray.map(skill => sanitizeKeyword(skill));
    formattedSkillsArray.push(formattedSkillArray);
  });
  return formattedSkillsArray;
}

const getDefaultFormValuesFromCriteria = async ({ criteria, currentJobDetails = {} }) => {
  const {
    Id,
    Sources,
    PortalQueries,
    Titles = [],
    Skills = {},
    QueryString,
    ActiveSearchString,
    LastUpdatedFrom = 180,
    VisaStatuses,
    Education,
    Industries,
    Companies,
    Name,
    Email,
    Mobile,
    IsExactTitleMatchRequired = false,
  } = criteria;

  const jobLevelOrCriteriaValues = getJobLevelOrCriteriaValues({ currentJobDetails, criteria });
  const isScoutingAgentCriteria = !isNumericString(Id);
  const { locations, range, isMultiLocation, experience, autoExpand, isRemoteLocation } = jobLevelOrCriteriaValues;
  const primaryLocation = locations?.find(x => x.IsPrimary) ?? locations?.[0];
  const { CountryCode, Location, State, Postalcode } = primaryLocation ?? {};
  const resumeLastUpdated = convertFromDays(LastUpdatedFrom);
  const { Location: criteriaLocation, Postalcode: criteriaPostalCode } = criteria?.Locations[0] || {
    Location: null,
    Postalcode: null,
  };
  const agentLocation = {
    CountryCode,
    Location: criteriaLocation,
    Zipcode: criteriaPostalCode,
  };

  const jobLocation = {
    CountryCode,
    Location: State ?? Location,
    Zipcode: Postalcode,
  };
  const agentExperience = {
    MinExperience: criteria.Experience?.MinYears || null,
    MaxExperience: criteria.Experience?.MaxYears || null,
  };
  let locationType = isMultiLocation ? 'MultiLocation' : 'Location';
  if (isScoutingAgentCriteria) {
    locationType = criteria.IsMultiLocation ? 'MultiLocation' : 'Location';
  }

  return {
    Id,
    Sources,
    PortalQueries,
    Titles: Titles.map(x => ({
      id: uuid.v4(),
      isSelected: x.IsPreferred,
      name: x.Title,
    })),
    Skills: {
      Prefers: await getPreferredSkills({ titles: Titles, prefers: Skills.Prefers, isAutoSelectionAllowed: true }),
      Excludes: Skills.Excludes?.map(x => ({ id: uuid.v4(), name: x, isSelected: true })),
      Musts: generateSkillsObject(getMustHaves(Skills.Musts)),
      Keywords: Skills.Prefers?.map(x => x.SkillName),
    },
    QueryString,
    ActiveSearchString,
    ResumeUpdateDurationType: resumeLastUpdated.resumeUpdateDurationType,
    ResumeUpdateDurationQuantity: resumeLastUpdated.resumeUpdateDurationQuantity,
    Experience: isScoutingAgentCriteria ? agentExperience : experience,
    LocationType: locationType,
    Location: isScoutingAgentCriteria ? agentLocation : jobLocation,
    IsStateWideSearch: !!State,
    SourcingLocations: isScoutingAgentCriteria && criteria.IsMultiLocation ? criteria.Locations : locations,
    AutoExpand: autoExpand,
    IsRemoteLocation: isScoutingAgentCriteria ? criteria?.IsRemoteLocation : isRemoteLocation,
    Range: range,
    VisaStatuses,
    Education,
    Industries: {
      Musts: Industries?.Musts?.filter(x => x.IsPreferred).map(x => x.Name),
      Excludes: Industries?.Excludes,
    },
    Companies: {
      Musts: Companies?.Musts?.filter(x => x.IsPreferred).map(x => x.Name),
      Excludes: Companies?.Excludes,
      IsCurrent: Companies?.IsCurrent,
      IsPast: Companies?.IsPast,
    },
    Name,
    Email,
    Mobile,
    IsExactTitleMatchRequired,
  };
};

const getDefaultManualSearchFormValues = async ({
  unsavedCriteria,
  aryaGeneratedCriteria = {},
  currentJobDetails,
  currentUserDetails,
  candidateContext,
  isPreferredKeywordAutoSelectEnabled,
}) => {
  return !_.isEmpty(unsavedCriteria)
    ? getDefaultFormValuesFromCriteria({
        criteria: unsavedCriteria,
        currentJobDetails: unsavedCriteria.Locations?.[0]?.CountryCode ? {} : currentJobDetails, // due to an issue CountryCode was not saved in prod accounts, to handle that we'll pick from currentJobDetails
      })
    : getDefaultFormValuesFromAryaGeneratedCriteria({
        aryaGeneratedCriteria,
        currentJobDetails,
        currentUserDetails,
        candidateContext,
        isPreferredKeywordAutoSelectEnabled,
      });
};

const getIsSourceEnabled = ({ source, allowActiveChannel }) => {
  return (
    (source.Portal && source.Portal.toLowerCase() !== 'indeed' && source.Portal.toLowerCase() !== 'linkedinadvance') ||
    source?.Group?.toLowerCase() === 'passive' ||
    (allowActiveChannel && source?.Group?.toLowerCase() === 'active')
  );
};

const getInitialSources = ({ jobCountryCode, userConfig, sources, portalsConfig, allowActiveChannel }) => {
  const sourcesConfig = userConfig?.Sources;
  return sources
    .filter(source => {
      const isSourceAuthorized = getIsSourceAuthorized({
        source,
        sourcesConfig,
        portalsConfig,
        jobCountryCode,
      });
      return isSourceAuthorized && getIsSourceEnabled({ source, allowActiveChannel });
    })
    .map(source => getSourceName(source));
};

const getManualSearchKeywordsToHighlight = ({
  titles = [],
  skills = {},
  queryString,
  activeSearchString,
  isAdvancedSearchV2Enabled,
}) => {
  const selectedTitles = titles.filter(x => x.IsPreferred).map(x => x.Title);
  let searchStringKeywords = [];
  let skillKeywords = [];
  let mustHaves = [];
  if (activeSearchString === 'AI') {
    const preferredSkills = skills.Prefers?.filter(x => x.IsPreferred).map(x => x.SkillName) ?? [];
    const prominentSkills = skills.Prefers?.filter(x => x.IsProminent).map(x => x.SkillName) ?? [];
    skillKeywords = [...prominentSkills, ...preferredSkills];
  } else searchStringKeywords = _.flatten(generateArrayFromBooleanString(queryString));
  if (isAdvancedSearchV2Enabled || activeSearchString === 'AI') {
    mustHaves = _.flatten(generateArrayFromBooleanString(skills.Musts));
  }
  const allKeywordsToHighlight = [...selectedTitles, ...skillKeywords, ...searchStringKeywords];
  return { allKeywordsToHighlight, mustHavesKeywords: mustHaves };
};

const getIndividualSourceDistributedCountInAllTab = ({ totalCount, sourceVsTotalCount, sources }) => {
  const availableCount = Object.values(sourceVsTotalCount).reduce((partialSum, a) => partialSum + a, 0);
  if (availableCount <= totalCount) return sourceVsTotalCount;
  let requiredCount = 0;
  const sourceVsDistributedCount = {};
  const _sourceVsTotalCount = { ...sourceVsTotalCount };
  const x = source => {
    if (_sourceVsTotalCount[source] > 0 && requiredCount !== totalCount) {
      requiredCount += 1;
      sourceVsDistributedCount[source] = (sourceVsDistributedCount[source] ?? 0) + 1;
      _sourceVsTotalCount[source] -= 1;
    }
  };
  while (requiredCount !== totalCount) {
    sources.forEach(x);
  }
  return sourceVsDistributedCount;
};

const generateAryaActivatePayloadWithoutPersonalInfo = ({ criteria }) => {
  return _.omit(criteria, ['Mobile', 'Email', 'Name']);
};

const generateManualSearchPayloadWithoutPersonalInfo = ({ criteria }) => {
  const updatedCriteria = _.omit(criteria, ['Mobile', 'Email', 'Name']);
  return {
    Criteria: updatedCriteria.Criteria,
    Id: updatedCriteria.Id,
    IsDraft: updatedCriteria.IsDraft,
    JobId: updatedCriteria.JobId,
  };
};
const getManualSearchLabelForFormKey = ({ key }) => {
  if (key === 'Mobile') return 'Phone';
  return key;
};

export const changeCursorPosition = (inputField, inputValue, isDoubleQuotesAllowed) => {
  const currentCursorPosition = inputField?.selectionStart;
  const mustHaveInputLength = inputValue.length;
  const hasWhitespace = /\s/.test(inputValue);
  const shouldChangeCursorPosition = hasWhitespace && isDoubleQuotesAllowed && inputValue.endsWith('"');
  const isCursorChangeRequired = currentCursorPosition === mustHaveInputLength;
  if (shouldChangeCursorPosition && isCursorChangeRequired) {
    inputField.setSelectionRange(mustHaveInputLength - 1, mustHaveInputLength - 1);
  }
};

export const aryaRankingDisabledPopover = (
  <div className="tooltipUnlockWrapper">
    <div className="tooltipUnlockHeader">Unlock Arya Ranked</div>
    <div className="toolTipUnlockBody">
      <span>
        {' '}
        Upgrade to Arya Auto plan to access candidate scoring, ranking and proactive candidate sourcing matching to job
        criteria.<a href="mailto:support@leoforce.com"> Contact Support</a>
      </span>
    </div>
  </div>
);

export const getAdvancedSearchPageName = (candidateContext, activeTab, featureToggleList, smartRecruiterVersion) => {
  return candidateContext === 'job' ? 'AryaSearchCandidates' : 'SegmentPage';
};
export const getManualSearchModifiedSource = (candidateContext, isNonInternalPortalForSegmentEnabled) => {
  return candidateContext === 'segment' && !isNonInternalPortalForSegmentEnabled
    ? { Portal: 'Internal', Type: 'Primary' }
    : { Portal: 'All', Type: 'Primary' };
};

export const getPersonalInfoSupportedSourceName = sources => {
  const portalNames = sources.map(source => source.Portal);
  const groupNames = sources.map(group => group?.Group);

  const isPassivePresent = portalNames.includes('Passive') || groupNames.includes('Passive');
  if (!isPassivePresent) return 'Social';
  const isSocialPresent = portalNames.includes('Social') || groupNames.includes('Social');
  return isSocialPresent ? 'Passive/Social' : 'Passive';
};

export const isExactMatchSearch = manualCriteria => {
  if (manualCriteria?.IsExactTitleMatchRequired) {
    return true;
  }

  const { Companies } = manualCriteria || {};
  const isPast = Companies?.IsPast;
  const isCurrent = Companies?.IsCurrent;

  // eslint-disable-next-line no-bitwise
  return isPast ^ isCurrent;
};

const diversityMessageForNameAndUniversity = (name, type) =>
  `While diversity is active for ${name}, you would not be able to search for candidates based on their ${type}.`;

export {
  generateManualSearchPayload,
  getManualSearchLabelForFormKey,
  mapSkillSuggestions,
  getDefaultManualSearchFormValues,
  getDefaultFormValuesFromCriteria,
  getDefaultFormValuesFromAryaGeneratedCriteria,
  getInitialSources,
  getManualSearchKeywordsToHighlight,
  getIndividualSourceDistributedCountInAllTab,
  generateAryaActivatePayloadWithoutPersonalInfo,
  generateManualSearchPayloadWithoutPersonalInfo,
  mapSkillSuggestionsWithMaxSelectionLimit,
  diversityMessageForNameAndUniversity,
};
