import axios from 'axios';
import { apiV1Client, apiV3Client, apiV4Client, apiV5Client, mycareersClient } from './BaseRepository';

export function fetchCandidateList(candidateFilter) {
  return apiV3Client.post(`jobs/${candidateFilter.jobId}/candidates/_search`, {
    Statuses: [candidateFilter.status],
    ConnectionStatus: candidateFilter.connectionStatus,
    SearchQuery: candidateFilter.q,
    Sources: candidateFilter.sources,
    Industries: candidateFilter.industries,
    Ratings: candidateFilter.ratings,
    Movers: candidateFilter.movers,
    Size: candidateFilter.count,
    From: candidateFilter.skip,
    SortBy: candidateFilter.sortBy,
    IsViewed: candidateFilter.viewed,
    IsDiverse: candidateFilter.diverse,
  });
}
function candidateSearchWithCancellation() {
  let cancelRequest;
  const { CancelToken } = axios;
  return (candidateFilter, isAdvancedRejectReasonsEnabled, abortIfDuplicated = true) => {
    if (cancelRequest && abortIfDuplicated) {
      cancelRequest('Operation canceled due to new request.');
    }
    return (isAdvancedRejectReasonsEnabled ? apiV5Client : apiV3Client)
      .post(
        `jobs/${candidateFilter.jobId}/candidates/_search`,
        { ...candidateFilter, IsAggregationsRequired: false },
        {
          cancelToken: new CancelToken(c => {
            cancelRequest = c;
          }),
        }
      )
      .catch(error => {
        if (axios.isCancel(error)) throw new Error('Request Cancelled');
        throw error;
      });
  };
}
export function fetchCandidateDetails(candidateFilter, isShowAllClientCandidateStatus) {
  return apiV4Client.get(`jobs/${candidateFilter.jobId}/candidates/${candidateFilter.candidateId}`, {
    params: {
      originalResume: false, // passing originalResume as false in order to get the converted resume for docx
      showAllClientCandidateStatus: isShowAllClientCandidateStatus,
    },
  });
}

export function undoApplyFromPortal(jobId, candidateId) {
  return mycareersClient.post(`api/candidateJobs/jobs/${jobId}/apply/_revert`, {
    AryaCandidateId: candidateId,
  });
}

export function changeCandidateStatus(status) {
  const candidateStatusChangePayload = {
    Status: status.status,
    IncludeSimilar: status.includeSimilar,
    StatusReasons: status.reasons,
    IsViewed: status.isViewed,
  };
  if (status.subsegmentId) {
    candidateStatusChangePayload.SubsegmentIds = [parseInt(status.subsegmentId, 10)];
    candidateStatusChangePayload.IsSubSegmentOnly = status.IsSubSegmentOnly;
  }
  if (status.mustHaves?.length) {
    candidateStatusChangePayload.MustHaves = status.mustHaves;
  }
  return apiV4Client.patch(`jobs/${status.jobId}/candidates/${status.candidateId}`, candidateStatusChangePayload);
}
export function updatePublishedCandidateStatus({ jobId, candidateId, status, statusReasons, mustHaves }) {
  return apiV3Client.post(`jobs/${jobId}/candidates/${candidateId}/_update`, {
    PublishStatus: status,
    PublishStatusReasons: statusReasons,
    MustHaves: mustHaves,
  });
}

export function getBulkCandidateRecommendPayload({
  recommendedInfoUpdateCommand,
  recommendationSource,
  searchCriteria = {},
  actionName,
  subsegmentId,
  recordId,
  searchCriteriaType,
}) {
  const bulkCandidateRecommendPayload = {};

  if (recommendedInfoUpdateCommand?.length) {
    bulkCandidateRecommendPayload.RecommendedInfoUpdateCommands = recommendedInfoUpdateCommand;
    if (recommendationSource)
      bulkCandidateRecommendPayload.RecommendedInfo = {
        RecommendationSource: recommendationSource,
      };
    if (recommendationSource === 'AdvanceSearch')
      bulkCandidateRecommendPayload.RecommendedInfo = {
        ...bulkCandidateRecommendPayload.RecommendedInfo,
        RecordId: recordId,
        SearchCriteriaType: searchCriteriaType,
      };
  } else {
    const { userGivenCriteria, candidateSearchCriteria, excludedCandidates, sourceConfiguration } = searchCriteria;
    bulkCandidateRecommendPayload.UserGivenCriteria = userGivenCriteria;
    bulkCandidateRecommendPayload.CandidateSearchCriteria = candidateSearchCriteria;
    bulkCandidateRecommendPayload.ExcludedCandidates = excludedCandidates;
    bulkCandidateRecommendPayload.RecommendedInfo = {
      SourceConfigId: sourceConfiguration?.Id ?? null,
      SourceGroupId: sourceConfiguration?.SourceGroupId ?? null,
      Status: actionName,
      RecommendationSource: recommendationSource,
    };
    if (recommendationSource === 'AdvanceSearch')
      bulkCandidateRecommendPayload.RecommendedInfo = {
        ...bulkCandidateRecommendPayload.RecommendedInfo,
        RecordId: recordId,
        SearchCriteriaType: searchCriteriaType,
      };
  }
  if (subsegmentId) bulkCandidateRecommendPayload.RecommendedInfo.SubsegmentIds = [subsegmentId];
  return bulkCandidateRecommendPayload;
}

export function getBulkSegmentCandidateRecommendPayload({
  searchCriteria = {},
  count,
  actionName,
  includeCandidatesWithStatus,
  isPartialRecommend,
  SubsegmentIds,
}) {
  const bulkCandidateRecommendPayload = {};
  if (SubsegmentIds) bulkCandidateRecommendPayload.AddToSubsegmentIds = SubsegmentIds;
  const { candidateSearchCriteria, excludedCandidates } = searchCriteria;
  bulkCandidateRecommendPayload.CandidateSearchCriteria = candidateSearchCriteria;
  bulkCandidateRecommendPayload.ExcludedCandidates = excludedCandidates;
  bulkCandidateRecommendPayload.Count = count;
  bulkCandidateRecommendPayload.Status = actionName;
  if (includeCandidatesWithStatus)
    bulkCandidateRecommendPayload.IncludeCandidatesWithStatus = includeCandidatesWithStatus;
  if (isPartialRecommend) bulkCandidateRecommendPayload.AllowPartialAdditionIfExceedingLimit = isPartialRecommend;
  return bulkCandidateRecommendPayload;
}

export function bulkCandidateRecommend({
  jobId,
  recommendedInfoUpdateCommand,
  searchCriteria,
  recommendationSource,
  subsegmentId,
  actionName = 'Shortlisted',
  recordId,
  searchCriteriaType,
}) {
  const bulkCandidateRecommendPayload = getBulkCandidateRecommendPayload({
    recommendedInfoUpdateCommand,
    searchCriteria,
    recommendationSource,
    actionName,
    subsegmentId,
    recordId,
    searchCriteriaType,
  });
  return apiV3Client.post(`jobs/${jobId}/candidates/_bulkRecommend`, bulkCandidateRecommendPayload);
}

export function bulkSegmentCandidateRecommend({
  jobId,
  searchCriteria,
  count,
  actionName,
  includeCandidatesWithStatus,
  isPartialRecommend,
  SubsegmentIds,
}) {
  const bulkCandidateRecommendPayload = getBulkSegmentCandidateRecommendPayload({
    searchCriteria,
    count,
    actionName,
    includeCandidatesWithStatus,
    isPartialRecommend,
    SubsegmentIds,
  });
  return apiV1Client.post(`segments/${jobId}/candidates/CandidateAddRequest`, bulkCandidateRecommendPayload);
}

export function fetchBulkCandidateNotes(jobId, filter) {
  return apiV3Client.post(`jobs/${jobId}/notes/_bulksearch`, filter);
}
export function addPersonIdToCandidate(jobId, candidateId, PersonId, ConversationId) {
  return apiV3Client.post(`jobs/${jobId}/candidates/${candidateId}/_connect`, {
    PersonId,
    ConversationId,
  });
}
export function fetchCandidateNotes(candidateFilter) {
  return apiV3Client.get(`jobs/${candidateFilter.jobId}/candidates/${candidateFilter.candidateId}/notes`);
}
export function postCandidateNotes(candidateFilter, notes) {
  return apiV3Client.post(`jobs/${candidateFilter.jobId}/candidates/${candidateFilter.candidateId}/notes`, notes);
}
export function unlockCandidateResume(candidateFilter) {
  return apiV3Client.get(
    `jobs/${candidateFilter.jobId}/candidates/${candidateFilter.candidateId}/_unlock?countrycode=${candidateFilter.countryCode}`
  );
}
export function downloadResume(candidateId, resumeName) {
  return apiV3Client.get(`/Candidates/${candidateId}/Resumes`.concat(resumeName ? `?resumeName=${resumeName}` : ''), {
    responseType: 'blob',
  });
}

const getBulkEmailSendCommand = ({
  candidates,
  email,
  recommendationSource,
  searchCriteria = {},
  recordId,
  searchCriteriaType,
}) => {
  const bulkEmailSendCommand = {
    EmailDetails: {
      To: email.To,
      From: email.From,
      TemplateId: email.TemplateId,
      Subject: email.Subject,
      PreHeader: email.PreHeader,
      Body: email.Body,
    },
  };
  if (recommendationSource) {
    bulkEmailSendCommand.RecommendationSource = recommendationSource;
  }
  if (recordId && searchCriteriaType) {
    bulkEmailSendCommand.RecordId = recordId;
    bulkEmailSendCommand.SearchCriteriaType = searchCriteriaType;
  }
  if (candidates?.length) {
    bulkEmailSendCommand.Candidates = candidates;
  } else {
    const { userGivenCriteria, candidateSearchCriteria, excludedCandidates } = searchCriteria;
    bulkEmailSendCommand.UserGivenCriteria = userGivenCriteria;
    bulkEmailSendCommand.CandidateSearchCriteria = candidateSearchCriteria;
    bulkEmailSendCommand.ExcludedCandidates = excludedCandidates;
  }
  return bulkEmailSendCommand;
};
export function sendBulkMails({ jobId, candidates, email }) {
  const bulkEmailSendCommand = getBulkEmailSendCommand({ candidates, email });
  return apiV3Client.post(`jobs/${jobId}/candidates/_sendemail`, bulkEmailSendCommand);
}

export function sendQuickSearchBulkMails({
  jobId,
  candidates,
  email,
  searchCriteria,
  recommendationSource,
  recordId,
  searchCriteriaType,
}) {
  const bulkEmailSendCommand = getBulkEmailSendCommand({
    candidates,
    email,
    searchCriteria,
    recommendationSource,
    recordId,
    searchCriteriaType,
  });
  return apiV3Client.post(`jobs/${jobId}/candidates/_sendemail/quicksearch`, bulkEmailSendCommand);
}

const getBulkDripSendCommand = ({
  candidates,
  email,
  searchCriteria = {},
  recommendationSource,
  recordId,
  searchCriteriaType,
}) => {
  const bulkDripSendCommand = {
    DripDetails: {
      ToEmailAddress: email.To,
      FromEmailAddress: email.From,
      DripTemplate: {
        Drops: email.Drops,
      },
    },
    RecordId: recordId,
    SearchCriteriaType: searchCriteriaType,
  };
  if (recommendationSource) {
    bulkDripSendCommand.RecommendationSource = recommendationSource;
  }
  if (candidates?.length) {
    bulkDripSendCommand.Candidates = candidates;
  } else {
    const { userGivenCriteria, candidateSearchCriteria, excludedCandidates } = searchCriteria;
    bulkDripSendCommand.UserGivenCriteria = userGivenCriteria;
    bulkDripSendCommand.CandidateSearchCriteria = candidateSearchCriteria;
    bulkDripSendCommand.ExcludedCandidates = excludedCandidates;
  }
  return bulkDripSendCommand;
};
export function sendBulkDripMails({ jobId, candidates, email }) {
  const bulkDripSendCommand = getBulkDripSendCommand({ candidates, email });
  return apiV3Client.post(`jobs/${jobId}/candidates/_senddrip`, bulkDripSendCommand);
}

export function sendQuickSearchBulkDripMails({
  jobId,
  candidates,
  email,
  searchCriteria,
  recommendationSource,
  recordId,
  searchCriteriaType,
}) {
  const bulkDripSendCommand = getBulkDripSendCommand({
    candidates,
    email,
    searchCriteria,
    recommendationSource,
    recordId,
    searchCriteriaType,
  });
  return apiV3Client.post(`jobs/${jobId}/candidates/_senddrip/quicksearch`, bulkDripSendCommand);
}
export function sendMail(jobId, candidateId, email) {
  return apiV3Client.post(`jobs/${jobId}/candidates/${candidateId}/_sendemail`, email);
}

const getBulkMessageSendCommand = ({
  candidates,
  message,
  recommendationSource,
  searchCriteria = {},
  recordId,
  searchCriteriaType,
}) => {
  const bulkMessageSendCommand = {
    MessageDetails: message,
    RecordId: recordId,
    SearchCriteriaType: searchCriteriaType,
  };
  if (recommendationSource) {
    bulkMessageSendCommand.RecommendationSource = recommendationSource;
  }
  if (candidates?.length) {
    bulkMessageSendCommand.Candidates = candidates;
  } else {
    const { userGivenCriteria, candidateSearchCriteria, excludedCandidates } = searchCriteria;
    bulkMessageSendCommand.UserGivenCriteria = userGivenCriteria;
    bulkMessageSendCommand.CandidateSearchCriteria = candidateSearchCriteria;
    bulkMessageSendCommand.ExcludedCandidates = excludedCandidates;
  }
  return bulkMessageSendCommand;
};
export function sendBulkMessage(jobId, candidates, message) {
  const bulkMessageSendCommand = getBulkMessageSendCommand({ candidates, message });
  return apiV3Client.post(`jobs/${jobId}/candidates/_sendmessage`, bulkMessageSendCommand);
}

export function sendQuickSearchBulkMessage({
  jobId,
  candidates,
  message,
  searchCriteria,
  recommendationSource,
  recordId,
  searchCriteriaType,
}) {
  const bulkMessageSendCommand = getBulkMessageSendCommand({
    candidates,
    message,
    searchCriteria,
    recommendationSource,
    recordId,
    searchCriteriaType,
  });
  return apiV3Client.post(`jobs/${jobId}/candidates/_sendmessage/quicksearch`, bulkMessageSendCommand);
}
export function sendMessage(jobId, candidateId, message) {
  return apiV3Client.post(`jobs/${jobId}/candidates/${candidateId}/_sendmessage`, message);
}
export function sendDrip(jobId, candidates, drip) {
  const candidateId = candidates[0].Id;
  return apiV3Client.post(`jobs/${jobId}/candidates/${candidateId}/_senddrip`, {
    ToEmailAddress: drip.To,
    FromEmailAddress: drip.From,
    DripTemplate: drip,
  });
}
export function bulkPublishCandidates(jobId, candidates) {
  return apiV3Client.post(`jobs/${jobId}/candidates/_bulkPublish`, candidates);
}
export function updateBulkConnectStatus(jobId, candidates, status) {
  return apiV3Client.post(`jobs/${jobId}/candidates/_bulkconnect`, {
    ConnectionStatus: status,
    CandidateIds: candidates,
  });
}
export function fetchCandidateIntel(jobId, candidateId) {
  return apiV3Client.get(`jobs/${jobId}/candidates/${candidateId}/intel`);
}
export function fetchTryNowCandidates(filter) {
  return apiV3Client.post('candidates/_trynow', filter);
}
export function fetchTryNowCandidateDetails(candidateId, countryCode = 'US') {
  return apiV3Client.get(`candidates/${candidateId}/_samplecandidate?countryCode=${countryCode}`);
}
export function fetchBulkEventStatus(candidateIds, eventName) {
  return apiV3Client.post(`candidates/_bulkeventstatus`, {
    CandidateIds: candidateIds,
    EventName: eventName,
  });
}
export function fetchBulkContacts(jobId, candidateIds, excludeCandidatesWithContacts) {
  return apiV3Client.post(`jobs/${jobId}/candidates/_fetchcontact`, {
    CandidateIds: candidateIds,
    ExcludeCandidatesWithContacts: excludeCandidatesWithContacts,
  });
}

function fetchCandidateRank({ jobId, candidateRankQuery, IsConnectedV2 }) {
  return apiV3Client.post(`jobs/${jobId}/candidates/status`, {
    CandidateId: candidateRankQuery.candidateId,
    CandidateGuid: candidateRankQuery.candidateGuid,
    ConnectionStatus: candidateRankQuery.connectionStatus,
    IsScoutingAgentCandidate: candidateRankQuery.isScoutingAgentCandidate,
    IsConnectedV2,
  });
}

export function getCandidateSpecifications(payload) {
  return apiV3Client.post(`/MasterData`, {
    MasterDataTypes: payload,
  });
}
export function updateCandidateBookmarkStatus(payload) {
  return apiV3Client.post(`jobs/${payload.jobId}/candidates/_favourite`, {
    CandidateIds: payload.candidateIds,
    IsFavourite: payload.isFavourite,
  });
}
export function fetchCandidateTags(payload) {
  return apiV3Client.post(
    `candidates/_globaltag?getGlobalTags=${payload.getGlobalTags}&jobId=${payload.jobId}`,
    payload.candidateIds
  );
}
export function fetchCandidateGlobalTags(payload) {
  return apiV3Client.post(`candidates/_globaltag?getGlobalTags=true`, payload.candidateIds);
}
export const bulkCandidateTransfer = filter => {
  return apiV3Client.post(`jobs/candidates/_transfer`, {
    candidateIds: filter?.candidateIds,
    sourceJobId: filter.jobId,
    destinationJobId: filter?.destinationJobId,
    action: filter.actionName,
    FilterContextId: filter?.filterContextId,
    ApplyCount: filter?.ApplyCount,
    SubsegmentIds: filter?.subsegmentId,
    DestinationSubsegmentId: filter?.destinationSubsegment,
  });
};
export const bulkCandidateRemove = filter => {
  return apiV3Client.post(`jobs/candidates/_remove`, {
    candidateIds: filter?.candidateIds,
    sourceJobId: filter.jobId,
    FilterContextId: filter?.filterContextId,
    ApplyCount: filter?.ApplyCount,
    SubsegmentIds: filter?.SubSegmentIds,
    IsSubSegmentOnly: filter?.IsSubSegmentOnly,
  });
};
export const bulkCandidateShortList = filter => {
  return apiV3Client.post(`jobs/${filter.jobId}/candidates/_add`, {
    candidateIds: filter?.candidateIds,
    sourceJobId: filter.jobId,
    FilterContextId: filter?.filterContextId,
    ApplyCount: filter?.ApplyCount,
    SubsegmentIds: filter?.SubsegmentIds,
  });
};
export const fetchCandidateActivityLogs = filter => {
  return apiV3Client.post(`Candidates/${filter.candidateId}/activity/search`, {
    From: filter.from,
    Size: filter.size,
  });
};
export function fetchCandidateJobs(filter = {}) {
  const {
    candidateId,
    CreatedBy,
    entityType,
    CandidateStages,
    JobStatuses,
    searchTerm = '',
    From = 0,
    Size = 10,
  } = filter;
  return apiV3Client.post(`Candidates/${candidateId}/jobs/history?entityType=${entityType}`, {
    From,
    Size,
    CandidateStages,
    JobStatuses,
    SearchTerm: searchTerm,
    CreatedBy,
  });
}
const getFilters = filter => {
  const {
    from,
    size,
    searchTerm,
    titles,
    skills,
    countryCodes,
    location,
    industries,
    candidateExperience,
    candidateTitle,
  } = filter;
  return {
    From: from,
    Size: size,
    SearchTerm: searchTerm,
    Titles: titles,
    Locations: location,
    CandidateExperience: candidateExperience,
    Industries: industries,
    JobStatuses: ['Open'],
    ExcludeJobStatuses: [],
    AggregateData: true,
    Skills: skills,
    CountryCodes: [countryCodes],
    CandidateTitle: candidateTitle,
  };
};
export function fetchCandidateMatchingJobs(filter = {}) {
  const payload = getFilters(filter);
  return apiV1Client.post(`candidates/${filter.candidateId}/jobs/_match`, payload);
}

export const updateCandidateViewStatus = (candidateId, viewStaus) => {
  return apiV1Client.post(`candidates/${candidateId}/view`, viewStaus);
};

export function fetchCandidateLastViewedStatus(payload) {
  const { CandidateIds, JobGuid, showOnlyCurrentUserViewedStatus: isViewedByMe } = payload;
  return apiV3Client.post(`candidates/_viewStatus`, {
    CandidateIds,
    JobGuid,
    isViewedByMe,
  });
}

function candidateAggregationFiltersWithCancellation() {
  let cancelRequest;
  const { CancelToken } = axios;
  return (candidateFilter, abortIfDuplicated = true) => {
    if (cancelRequest && abortIfDuplicated) {
      cancelRequest('Operation canceled due to new request.');
    }
    return apiV3Client
      .post(`jobs/${candidateFilter.jobId}/candidates/_aggregations`, candidateFilter, {
        cancelToken: new CancelToken(c => {
          cancelRequest = c;
        }),
      })
      .catch(error => {
        if (axios.isCancel(error)) throw new Error('Request Cancelled');
        throw error;
      });
  };
}

export function fetchAppliedBucketScoringStatus(jobId) {
  return apiV3Client.post(`jobs/${jobId}/candidates/scoring/_status`);
}

export function publishedCandidates({ candidates: AryaCandidateIds, jobId }) {
  return apiV4Client.post(`jobs/${jobId}/candidates/_bulkPublish`, { AryaCandidateIds });
}

export function removeFavouriteCandidate({ aryaCandidateIds: AryaCandidateIds, jobId }) {
  return apiV3Client.post(`jobs/${jobId}/favourite/_remove`, { AryaCandidateIds });
}
export function bulkCandidateRecommendToJobFromSegment(payload) {
  return apiV3Client.post(`jobs/${payload.JobId}/candidates/_bulkrecommend/subsegment`, payload);
}

export const candidateSearch = candidateSearchWithCancellation();
export const candidateAggregationFilters = candidateAggregationFiltersWithCancellation();
export { fetchCandidateRank };
