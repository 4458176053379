import { defineMessages } from 'react-intl';

const messages = defineMessages({
  jobTitleSymonyms: {
    id: 'Job.title',
    defaultMessage: 'Job Title Synonyms',
  },
  jobDescription: {
    id: 'Job.description',
    defaultMessage: 'Job Description',
  },
  jobSummary: {
    id: 'Job.Summary',
    defaultMessage: 'Job Summary',
  },
  jobOpening: {
    id: 'Job.openings',
    defaultMessage: 'Total Openings',
  },
  jobExperience: {
    id: 'Job.experience',
    defaultMessage: 'Job Experience',
  },
  jobLocation: {
    id: 'Job.location',
    defaultMessage: 'Location',
  },
  jobMiles: {
    id: 'Job.miles',
    defaultMessage: 'Miles',
  },
  remoteJob: {
    id: 'Job.remote',
    defaultMessage: 'Remote',
  },
  jobResume: {
    id: 'Job.resume',
    defaultMessage: 'Resume',
  },
  jobIndustry: {
    id: 'Job.industry',
    defaultMessage: 'Industry',
  },
  jobTag: {
    id: 'Job.tag',
    defaultMessage: 'Tags',
  },
  jobMustHaveSkills: {
    id: 'Job.musthaveskills',
    defaultMessage: 'Must Have skills',
  },
  jobNiceToHaveSkills: {
    id: 'Job.nicehaveskills',
    defaultMessage: 'Nice to have skills',
  },
  resumeUploaded: {
    id: 'Job.resumeuploaded',
    defaultMessage: 'Resume Uploaded',
  },
  noResumeUploaded: {
    id: 'Job.noresumeuploaded',
    defaultMessage: 'No Resume Uploaded',
  },
  no: {
    id: 'Job.no',
    defaultMessage: 'No',
  },
  years: {
    id: 'Job.years',
    defaultMessage: 'years',
  },
  to: {
    id: 'Job.to',
    defaultMessage: 'to',
  },
  of: {
    id: 'Job.of',
    defaultMessage: 'of',
  },
  na: {
    id: 'Job.na',
    defaultMessage: 'NA',
  },
  autoExpand: {
    id: 'Job.AutoExpand',
    defaultMessage: 'Open to more than 100 miles',
  },
  autoExpandPulse: {
    id: 'Job.AutoExpandPulse',
    defaultMessage: 'Search Entire Country',
  },
  createdon: {
    id: 'JobDetailHeader.createdon',
    defaultMessage: 'Created On',
  },
});

export default messages;
